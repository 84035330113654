.account-config {
	.p-details {
		padding: 10px;
	}
}

.dropzone {
	color: $brand-secondary;
	background: white;
	border:none;
	letter-spacing: 1px;
	font-weight: 400;
	transition: all .6s;
	&:hover {
		color: $brand-primary;
	}
	.fa {
		font-size: 30px;
		margin-bottom: 5px;
	}
	.dz-image {
	    border-radius: 5px !important;
	    border: 1px solid #4470b5;
	    background: #7cafff !important;
	}
}