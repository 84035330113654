$light-blue : #D9E1EF;
$bordeaux : #990000;
$table-green: #009933;

.section-online-holding {
  .no-data-be{
    background-color: #d9e1ef;
    margin-top:33px;
    font-family: "Helvetica Condensed", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding: 10px 8px;
    .xc{
      color:#4470b5
    }
    @media screen and (max-width: 1100px) {
      margin-top:25px;
    }
    @media screen and (max-width: 768px) {
      margin-top:15px;
    }
  }
  #prefooter {
    .logos {
      display: none;
    }
  }
  #header .menu ul li{
    &.m-usuario {
      display: inline-block!important;
      background: none;
      a {
        color: $contact-icon;
        font-size: $font-size-base * 1.125;
      }
    }
  }
  &__introduction {
    background:url(images/background.jpg) transparent top center no-repeat fixed;
    background-size:cover;
    height: $spacer*34.5;
    &.belist-nobe{
      height: auto;
    }
    @media only screen and (max-width: 568px) {
      height: auto;
      .title {
        padding-bottom: $spacer*8;
      }
    }
  }
  &__tables {
    background-color: $white;
    padding-bottom:25px;
    @media only screen and (max-width: 768px) {
      padding-bottom:15px;
    }
    @media only screen and (max-width: 500px) {
      padding-bottom:8px;
    }
    .app-inside {
      @extend .flex-r;
    }
  }
  &__current-accounts {
    @extend .row, .flex-r;
    margin-top: -$spacer*4.8;
    margin-bottom: $spacer*3.3;
  }
}

.current-account-table {
  @media only screen and (max-width: 576px) {
    margin-bottom: $spacer*2;
  }
  &__date {

  }
  &__value {
    margin-left: auto;
    &_negative {
      color: $bordeaux;
    }
  }
  &__header {
    @extend .flex-c;
    justify-content: center;
    font-weight: 700;
    align-items: center;
    height: $spacer*4.8;
    width: 100%;
    text-transform: uppercase;
    color: $white;
    font-family: $font-family-condensed-final;
    font-weight: 700;
    font-size: $font-size-base*1.125;
    background-color: $blue;
    text-align: center;
  }
  &__row {
    @extend .flex-r;
    align-items: center;
    padding-left: $spacer*1.8;
    padding-right: $spacer*1.8;
    height: $spacer*4;
    background-color: $light-blue;
    border-bottom: $spacer*.1 solid $white;
    &:last-of-type {
      border-bottom: none;
    }
  }
}

.holding-value-table {
  border-collapse: collapse;
  margin-bottom: 0;
  display: table;
  &__negative {
    color: $bordeaux;
  }
  thead {
    tr {
      th {
        font-family: $font-family-condensed-final;
        font-weight: 500;
        font-size: $font-size-base;
        background-color: $blue;
        color: $white;
        border-right: $spacer*.1 solid $white;
      }
    }
  }
  tbody {
    tr {
      background-color: $light-blue;
      border-bottom: $spacer*.2 solid $white;
      td {
        border-color: $light-blue;
        border-right: $spacer*.1 solid $white;
        text-align: center;
        &.specie-name{
          border-right:0;
          text-align: right;
        }
      }
    }
  }
  tfoot {
    tr {
      background-color: #cccccc;
      color: $white;
      td {
        text-align: left;
        &:last-of-type {
          color: $black;
        }
      }
    }
  }
}

.stock-type-table {
  &__negative {
    color: $bordeaux;
  }
  &__positive {
    color: $table-green;
  }
  margin-bottom: $spacer*3.3;
  text-align: left;
  display: table;
  border-collapse: collapse;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
  thead {
    tr {
      th {
        border-right: $spacer*.1 solid $white;
        border-bottom: none;
        font-family: $font-family-condensed-final;
        font-weight: 500;
        font-size: $font-size-base;
        background-color: $blue;
        color: $white;
      }
    }
  }
  tbody {
    tr {
      border-bottom: $spacer*.2 solid $white;
      background-color: $light-blue;
      td {
        border-right: $spacer*.1 solid $white;
      }
    }
  }
}


.clients-producer h2{
  margin-bottom: 8px
}
.clients-producer ul li:hover{
  background: #c8d6ec
}
.clients-producer ul li.header{
  background: transparent;
  font-weight: bold;
  font-size:18px;
  border-bottom: 1px solid #002338
}
.clients-producer .col-actions a:hover{
  text-decoration: underline;
}
.clients-producer .col-state{
  text-align: center
}
.clients-producer .colx{
  position: relative;
  float: left;
  width: 33.33%;
}
.clients-producer{
  min-height: 400px
}
.clients-producer .cxp{
  display: none
}

@media screen and (max-width: 710px) {
  .clients-producer ul li.header,
  .clients-producer .itt .colx{
    display: none
  }
  .clients-producer .cxpa a{
    display: block
  }
  .clients-producer .cxp{
    display: block
  }
}

.holding-value-table thead tr th{
  vertical-align: middle;
}
.holding-value-table tfoot tr {
  background-color: #4470b5;
  color: #fff;
  border-bottom:1px solid #FFF;
}
.holding-value-table tfoot{
  font-size: 18px;
  font-family: "Helvetica Condensed", sans-serif;
}
.holding-value-table tfoot tr{
  width:100%
}
.holding-value-table tfoot tr td{
  border-top:0
}
.holding-value-table tfoot tr td:last-of-type{
  color: #fff;  
}
#content .title h3 .bootstrap-datetimepicker-widget{
  z-index: 1000000;
}
.table-xtrt.table-xtrrr{
  margin-top:33px;
  @media screen and (max-width: 1100px) {
    margin-top:25px;
  }
  @media screen and (max-width: 768px) {
    margin-top:15px;
  }
}
.table-xtrt{
  overflow-y: hidden;
  overflow-x: auto
}
.section-online-holding .tfoot-alt .tt{
  width:70%;
  box-sizing: border-box;
  padding-right:15px;
  float:left
}
.section-online-holding .tfoot-alt .tts{
  color:#FFF;
  width:30%;
  position: relative;
  text-align: right;
  font-weight: bold;
  box-sizing: border-box;
  padding-right:0;
  float:left
}
.holding-value-table tbody tr.cc-foot td{
  border-right:0;
  text-align: left;
  padding:0
}
.holding-value-table tbody tr.cc-foot{
  font-family: "Helvetica Condensed", sans-serif;
  font-weight: 700;
  margin-bottom: 0;
  border-bottom: 1px solid #FFF;
  font-size: 18px;
  line-height: 20px;
  overflow: hidden;
  background-color: #4470b5;
  color: #fff;
}
.holding-value-table tbody tr.cc-row{
  background:#bdc8dc
}
.txgreen,
.app-table .txgreen{
  color: #26af0e
}
.txred,
.app-table .txred{
  color: #990000
}
.app-table .txcenter{
  text-align: center
}
.app-table .txleft{
  text-align: left
}
.app-table .txright{
  text-align: right
}
.section-online-holding .tfoot-alt.tfoot-alt-total{
  display: block;
  font-size:24px;
  line-height: 28px;
}
.section-online-holding .tfoot-alt{
  font-family: "Helvetica Condensed", sans-serif;
  font-weight: 700;
  margin-bottom:0;
  border-bottom:1px solid #FFF;
  font-size: 18px;
  line-height: 20px;
  overflow:hidden;
  background-color: #4470b5;
  color: #fff;
}
.holding-value-table tfoot{
  display: none
}

.section-online-holding .holding-value-table td.specie-cc{
  text-align:left;
  padding-right:18px;
  @media screen and (max-width: 1100px){  
    padding-right:0;
  }
}
.section-online-holding .tfoot-alt{
  padding:10px 8px;
  @media screen and (max-width: 1100px){  
    padding:10px;
  }
}
body .holding-value-table th,
body .holding-value-table td .ittpadd,
body .holding-value-table td{
  padding:10px 8px;
  @media screen and (max-width: 1100px){  
    padding:8px 4px;
  }
}
.table-xtrt .table-xtr tbody{
  width:100%
}
.table-xtrt .table-xtr tfoot{
  width:100%
}
.table-xtrt .table-xtr{
  min-width:1000px;
}
#content .title h3 .input .arrlx.active{
  cursor: pointer;
  opacity: 1;
}
#content .title h3 .input .arrlx.active:hover{

}
#content .title h3 .input .arrlx{
  opacity: .3;
  transition: opacity .2s;
}
#content .title h3 .input .lbx{
  margin-right: 10px;
  display: inline-block
}
#content .title h3 .input select{
  font-size:14px;
  padding:5px 10px;
  width:180px;
  margin:0 5px;
  border:0;
  display: inline-block;
  border-radius: 5px;
  position: relative;
}
#content .title h3 .input input{
  font-size:16px;
  padding:10px 0;
  width:100px;
  text-align: center;
  border:0;
  border-radius: 5px;
  position: relative;
  margin-left: 10px;
}
#content .title h3 .input{
  font-size:18px;
  text-shadow: none;
  position: absolute;
  right:0;
  bottom:56px;
}

@media screen and (max-width: 1100px){  
  .holding-value-table{
    margin-bottom:0
  }
  .section-online-holding__current-accounts{
    margin-bottom: 25px;
  }
  .holding-value-table tfoot{
    display: none
  }

  .section-online-holding .tfoot-alt{
    display: block;
  }
  #content .title h3 .input{
    bottom:29px;
  }
  .section-online-holding__introduction{
    height: auto;
    padding-bottom:50px
  }
  body .user-menu{
    margin-bottom: 40px; 
  }

  #header .d522de .logo{
    width:auto
  }
}
@media screen and (max-width: 920px){ 
  #content .title {
    padding-top: 86px;
  }
  body .user-menu{
    margin-bottom: 20px;
    display:flex;
    flex-flow:column;
  }
  .user-menu__my-account .drop-down-list{
    margin-top: 10px !important
  } 
  /*.user-menu__option:first-child{
    text-align: left;
  }
  .user-menu__option:last-child{
    text-align: right;
  }*/
}
@media screen and (max-width: 768px){ 
  .current-account-table{
    margin-bottom: 15px
  }
  .section-online-holding__current-accounts{
    margin-bottom: 0;
  }
  #content .title h3{
    margin-top: 26px;
    @media screen and (max-width: 700px){ 
      margin-top: 5px;
    }
  }
  #content .title h3 .input {
    bottom: 28px;
  }
  .drop-down-list.select-change-date .dropdown-toggle{
    height: auto;
    padding-top:0;
    padding-bottom:0;
  }
  .user-menu__list{
    border-bottom:1px solid rgba(255,255,255,.4);
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
  }
  .user-me:not(.user-menu-producer){
	  .user-menu__option {
	    display: block;
	    text-align: center;
	    margin: 0 2px !important;
	    width: auto;
	    flex-grow: 1;
	    height: 35px !important;
	    &.active:hover,
	    &.active{
	      &:after{
	        width: 100%;
	      }
	    }
	    &:after{
	      width:100%;
	      bottom:0 !important;
	      height: 5px !important;
	      display: block
	    }
	  }
  }
  #content .title h3.xr {
    padding-top: 0 !important;
    text-align: center;
  }
  #content .title .title-button{
    margin:0 25px !important;
    font-size: 24px !important;
    border:0;
  }
  .user-menu__my-account{
    min-height: 73px;
    font-size:24px !important;
    .drop-down-list{
      max-width: none;
    }
  }
  .user-menu__list {
    width: 100%;
    margin: 0;
    font-size:20px;
    margin-top:30px; 
  }
}
@media screen and (max-width:700px){  
  .user-menu__my-account {
    width: 100%;
  }
  body .user-menu {
    margin-bottom: 10px; 

  }
  #content .section-online-holding__introduction .title h3 .input .lbx{
    display: none 
  }
  .bootstrap-select.btn-group.select-change-date .dropdown-toggle .filter-option,
  .bootstrap-select.btn-group.select-change-date .dropdown-menu li a span.text,
  .drop-down-list.select-change-date .span-ten-update{
    font-size:20px;
    padding-top:4px;
    padding-bottom:4px;
    line-height: 1.1;
    text-align: center;
    text-transform: none;
  }
  .user-menu__information{
    position: relative;
    .logout-menu{
      font-size: 20px;
      text-transform: uppercase;
      position: absolute;
      top:0;
      right: 0
    }
  }
  #content .title h3 .input .arrlx {
    font-size: 32px;
    position: relative;
    top: 6px;
  }
  #content .title .select-change-date, 
  .drop-down-list.select-change-date {
    width: calc(90% - 50px);
    margin: 0 13px;
  }
  .bootstrap-select.btn-group .dropdown-toggle .filter-option{
    margin-top: 0;
  }
  .drop-down-list.select-change-date .span-ten-update br{
    display: none
  }
  #content .title h3 {
    padding-top: 0;
    color: transparent;
    text-shadow: none;
    font-size: 0;
    padding-bottom: 20px;
  }
  #content .title h3 .input {
    position: static;
    color:#FFF;
    display: block;
    margin: 0;
    width: 100%;
    text-align: center;
    margin-top: 13px;
  }
}
@media screen and (max-width: 500px){ 
  .current-account-table{
    margin-bottom: 8px
  }
}
@media screen and (max-width: 568px){ 
  #content .title {
    padding-top: 85px;
  }
  #content .title h3 {
    //margin-top: 15px;
  }
  /*#content .title h3{
    font-size:40px;
    text-shadow: 0px 2px 0px #000000;
  }*/
  .user-menu .user-menu__list a.user-menu__option {
    font-size:18px;
    margin-top:0
  }
  body .section-online-holding__introduction .title {
    padding-bottom: 0
  }
}

@media screen and (max-width: 450px){ 
  .section-online-holding .tfoot-alt  .tts{
    background-color: #D9E1EF;
    color:#000;
    font-size: 22px;
    top:0
  }
  #content .title .select-change-date, .drop-down-list.select-change-date{
    width:calc(90% - 70px);   
  }
  .user-menu__list_cc{
    overflow: hidden;
    overflow-x:auto;
    padding-bottom: 17px;
  }
  .user-menu__list {
    display: inline-flex;
    flex-wrap: nowrap;
    width: auto;
    margin-top:20px;
    margin-bottom: 0;
    .user-menu__option{
      min-width: 150px;
      font-size:18px !important;
      padding-top: 10px;
      height: 32px !important;
      &:hover:not(.active){
        &:after{
          display: none;
        }
      }   
    }
  }
  .section-online-holding .tfoot-alt{
    padding: 0;
  }
  .section-online-holding .tfoot-alt .tts,
  .section-online-holding .tfoot-alt .tt{
    margin:auto;
    padding: 0.75rem;
    float:none;
    text-align: center;
    width:auto
  }
  .section-online-holding .tfoot-alt .nl{
    display: block
  }

}
@media screen and (max-width: 340px){ 
  .section-online-holding .tfoot-alt .nl{
    display: none
  }
}
@media screen and (max-width: 1100px){  
  body .menu-mobile{
    display: none
  }
}
body.open-rmenu {
  overflow:hidden;
  .rmenu {
    right: 0;
  }
}
.holding-value-table tbody tr,
.holding-value-table thead tr{  
  td, th {
    &.hide-cols-filter{
      display: none !important
    }
  }
}

$light-blue : #D9E1EF;
$bordeaux : #990000;
$table-green: #009933;

.section-online-holding {
  .no-data-be{
    background-color: #d9e1ef;
    margin-top:33px;
    font-family: "Helvetica Condensed", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding: 10px 8px;
    .xc{
      color:#4470b5
    }
    @media screen and (max-width: 1100px) {
      margin-top:25px;
    }
    @media screen and (max-width: 768px) {
      margin-top:15px;
    }
  }
  #prefooter {
    .logos {
      display: none;
    }
  }
  #header .menu ul li{
    &.m-usuario {
      display: inline-block!important;
      background: none;
      a {
        color: $contact-icon;
        font-size: $font-size-base * 1.125;
      }
    }
  }
  &__introduction {
    background:url(images/background.jpg) transparent top center no-repeat fixed;
    background-size:cover;
    height: $spacer*34.5;
    &.belist-nobe{
      height: auto;
    }
    @media only screen and (max-width: 568px) {
      height: auto;
      .title {
        padding-bottom: $spacer*8;
      }
    }
  }
  &__tables {
    background-color: $white;
    padding-bottom:25px;
    @media only screen and (max-width: 768px) {
      padding-bottom:15px;
    }
    @media only screen and (max-width: 500px) {
      padding-bottom:8px;
    }
    .app-inside {
      @extend .flex-r;
    }
  }
  &__current-accounts {
    @extend .row, .flex-r;
    margin-top: -$spacer*4.8;
    margin-bottom: $spacer*3.3;
  }
}

.current-account-table {
  @media only screen and (max-width: 576px) {
    margin-bottom: $spacer*2;
  }
  &__date {

  }
  &__value {
    margin-left: auto;
    &_negative {
      color: $bordeaux;
    }
  }
  &__header {
    @extend .flex-c;
    justify-content: center;
    font-weight: 700;
    align-items: center;
    height: $spacer*4.8;
    width: 100%;
    text-transform: uppercase;
    color: $white;
    font-family: $font-family-condensed-final;
    font-weight: 700;
    font-size: $font-size-base*1.125;
    background-color: $blue;
    text-align: center;
  }
  &__row {
    @extend .flex-r;
    align-items: center;
    padding-left: $spacer*1.8;
    padding-right: $spacer*1.8;
    height: $spacer*4;
    background-color: $light-blue;
    border-bottom: $spacer*.1 solid $white;
    &:last-of-type {
      border-bottom: none;
    }
  }
}

.holding-value-table {
  border-collapse: collapse;
  margin-bottom: 0;
  display: table;
  &__negative {
    color: $bordeaux;
  }
  thead {
    tr {
      th {
        font-family: $font-family-condensed-final;
        font-weight: 500;
        font-size: $font-size-base;
        background-color: $blue;
        color: $white;
        border-color: $white;
        border-right: $spacer*.1 solid $white;
      }
    }
  }
  tbody {
    tr {
      background-color: $light-blue;
      border-bottom: $spacer*.2 solid $white;
      td {
        border-color: $light-blue;
        border-right: 1px solid $white;
    		border-bottom:2px solid $white;
        text-align: center;
        &:last-child{
          border-right:0;        	
        }
        &.specie-name{
          border-right:0;
          text-align: right;
        }
      }
    }
  }
  tfoot {
    tr {
      background-color: #cccccc;
      color: $white;
      td {
        text-align: left;
        &:last-of-type {
          color: $black;
        }
      }
    }
  }
}

.stock-type-table {
  &__negative {
    color: $bordeaux;
  }
  &__positive {
    color: $table-green;
  }
  margin-bottom: $spacer*3.3;
  text-align: left;
  display: table;
  border-collapse: collapse;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
  thead {
    tr {
      th {
        border-right: $spacer*.1 solid $white;
        border-bottom: none;
        font-family: $font-family-condensed-final;
        font-weight: 500;
        font-size: $font-size-base;
        background-color: $blue;
        color: $white;
      }
    }
  }
  tbody {
    tr {
      border-bottom: $spacer*.2 solid $white;
      background-color: $light-blue;
      td {
        border-right: $spacer*.1 solid $white;
      }
    }
  }
}


.clients-producer h2{
  margin-bottom: 8px
}
.clients-producer ul li:hover{
  background: #c8d6ec
}
.clients-producer ul li.header{
  background: transparent;
  font-weight: bold;
  font-size:18px;
  border-bottom: 1px solid #002338
}
.clients-producer .col-actions a:hover{
  text-decoration: underline;
}
.clients-producer .col-state{
  text-align: center
}
.clients-producer .colx{
  position: relative;
  float: left;
  width: 33.33%;
}
.clients-producer{
  min-height: 400px
}
.clients-producer .cxp{
  display: none
}

@media screen and (max-width: 710px) {
  .clients-producer ul li.header,
  .clients-producer .itt .colx{
    display: none
  }
  .clients-producer .cxpa a{
    display: block
  }
  .clients-producer .cxp{
    display: block
  }
}

.holding-value-table thead tr th{
  vertical-align: middle;
}
.holding-value-table tfoot tr {
  background-color: #4470b5;
  color: #fff;
  border-bottom:1px solid #FFF;
}
.holding-value-table tfoot{
  font-size: 18px;
  font-family: "Helvetica Condensed", sans-serif;
}
.holding-value-table tfoot tr{
  width:100%
}
.holding-value-table tfoot tr td{
  border-top:0
}
.holding-value-table tfoot tr td:last-of-type{
  color: #fff;  
}
#content .title h3 .bootstrap-datetimepicker-widget{
  z-index: 1000000;
}
.table-xtrt.table-xtrrr{
  margin-top:33px;
  @media screen and (max-width: 1100px) {
    margin-top:25px;
  }
  @media screen and (max-width: 768px) {
    margin-top:15px;
  }
}
.table-xtrt{
  overflow-x: auto
}
.section-online-holding .tfoot-alt .tt{
  width:70%;
  box-sizing: border-box;
  padding-right:15px;
  float:left
}
.section-online-holding .tfoot-alt .tts{
  color:#FFF;
  width:30%;
  position: relative;
  text-align: right;
  font-weight: bold;
  box-sizing: border-box;
  padding-right:0;
  float:left
}
.holding-value-table tbody tr.cc-foot td{
  border-right:0;
  text-align: left;
  padding:0
}
.holding-value-table tbody tr.cc-foot{
  font-family: "Helvetica Condensed", sans-serif;
  font-weight: 700;
  margin-bottom: 0;
  border-bottom: 1px solid #FFF;
  font-size: 18px;
  line-height: 20px;
  overflow: hidden;
  background-color: #4470b5;
  color: #fff;
}
.holding-value-table tbody tr.cc-row{
  background:#bdc8dc
}
.txgreen,
.app-table .txgreen{
  color: #26af0e
}
.txred,
.app-table .txred{
  color: #990000
}
.app-table .txcenter{
  text-align: center
}
.app-table .txleft{
  text-align: left
}
.app-table .txright{
  text-align: right
}
.section-online-holding .tfoot-alt.tfoot-alt-total{
  display: block;
  font-size:24px;
  line-height: 28px;
}
.section-online-holding .tfoot-alt{
  font-family: "Helvetica Condensed", sans-serif;
  font-weight: 700;
  margin-bottom:0;
  border-bottom:1px solid #FFF;
  font-size: 18px;
  line-height: 20px;
  overflow:hidden;
  background-color: #4470b5;
  color: #fff;
}
.holding-value-table tfoot{
  display: none
}

.section-online-holding .holding-value-table td.specie-cc{
  text-align:left;
  padding-right:18px;
  @media screen and (max-width: 1100px){  
    padding-right:0;
  }
}
.section-online-holding .tfoot-alt{
  padding:10px 8px;
  @media screen and (max-width: 1100px){  
    padding:10px;
  }
}
body .holding-value-table th,
body .holding-value-table td .ittpadd,
body .holding-value-table td{
  padding:10px 8px;
  @media screen and (max-width: 1100px){  
    padding:8px 4px;
  }
}
.table-xtrt .table-xtr tbody{
  width:100%
}
.table-xtrt .table-xtr tfoot{
  width:100%
}
.table-xtrt .table-xtr{
  min-width:1000px;
}
#content .title h3 .input .arrlx.active{
  cursor: pointer;
  opacity: 1;
}
#content .title h3 .input .arrlx.active:hover{
	opacity: .8;
}
#content .title h3 .input .arrlx{
  opacity: .3;
  font-size: 35px;
  transition: opacity .2s;
  vertical-align: middle;
  position: relative;
  top: 1px;
  &.next{
  	margin-left: 15px;
  	margin-right: 10px;
  }
  &.prev{
  	margin-left: 10px;
  	margin-right: 15px;
  }
}
#content .title h3 .input .lbx{
	text-align: center;
	position: absolute;
	top: -25px;
	right: 0;
	left: 0;
	font-size: 18px;
}
#content .title h3 .input select{
	font-size:14px;
	padding:5px 10px;
	width:180px;
  margin:0 15px;
  border:0;
  display: inline-block;
  border-radius: 5px;
  position: relative;
  vertical-align: middle;
}
#content .title h3 .input input{
  font-size:16px;
  padding:10px 0;
  width:100px;
  text-align: center;
  border:0;
  border-radius: 5px;
  position: relative;
  margin-left: 10px;
}
#content .title h3 .input{
  font-size:18px;
  text-shadow: none;
  position: absolute;
  right:0;
  bottom:56px;
  &.input-to{
  	width:calc(50% - 15px);
  	.input-inside{
  		max-width: 400px;
  		margin:auto;
  		display: flex;
  	}
  }
}
#app-body .user-menu__my-account{
	max-width:calc(100% - 15px);
	min-width:60%;
	.drop-down-list.client-list {
		.bootstrap-select > .dropdown-toggle{
			margin-top: 0;
		}
	}
}
@media screen and (max-width: 1100px){  
  .holding-value-table{
    margin-bottom:0
  }
  .section-online-holding__current-accounts{
    margin-bottom: 25px;
  }
  .holding-value-table tfoot{
    display: none
  }

  .section-online-holding .tfoot-alt{
    display: block;
  }
  #content .title h3 .input{
    bottom:29px;
  }
  .section-online-holding__introduction{
    height: auto;
    padding-bottom:50px
  }
  body .user-menu{
    margin-bottom: 40px; 
  }

  #header .d522de .logo{
    width:auto
  }
}
@media screen and (max-width: 920px){ 
  #content .title {
    padding-top: 86px;
  }
  body .user-menu{
    margin-bottom: 20px;
    display:flex;
    flex-flow:column;
  }
  .user-menu__my-account .drop-down-list{
    margin-top: 10px !important
  } 
  /*.user-menu__option:first-child{
    text-align: left;
  }
  .user-menu__option:last-child{
    text-align: right;
  }*/
}
@media screen and (max-width: 768px){ 
  .current-account-table{
    margin-bottom: 15px
  }
  .section-online-holding__current-accounts{
    margin-bottom: 0;
  }
  #content .title h3{
    margin-top: 26px;
    @media screen and (max-width: 700px){ 
      margin-top: 5px;
    }
  }
  #content .title h3 .input {
    bottom: 28px;
  }
  .drop-down-list.select-change-date .dropdown-toggle{
    height: auto;
    padding-top:0;
    padding-bottom:0;
  }
  .user-menu:not(.user-menu-producer){
	  .user-menu__list{
	    border-bottom:1px solid rgba(255,255,255,.4);
	    margin-left: 10px;
	    margin-right: 10px;
	    width: auto;
	  }
	  .user-menu__option {
	  	display: block;
	  	text-align: center;
	  	margin: 0 2px !important;
	  	width: auto;
	  	flex-grow: 1;
	  	height: 35px !important;
	  	&.active:hover,
	  	&.active{
	  		&:after{
	  			width: 100%;
	  		}
	  	}
	  	&:after{
	  		width:100%;
	  		bottom:0 !important;
	  		height: 5px !important;
	  		display: block
	  	}
	  }
	}
  #content .title h3.xr {
    padding-top: 0 !important;
    text-align: center;
  }
  #content .title .title-button{
    margin:0 25px !important;
    font-size: 24px !important;
    border:0;
  }
  .user-menu__my-account{
    min-height: 73px;
    font-size:24px !important;
    .drop-down-list{
      max-width: none;
    }
  }
  .user-menu__list {
    width: 100%;
    margin: 0;
    font-size:20px;
    margin-top:30px; 
  }
}
@media screen and (max-width:700px){  
  .user-menu__my-account {
    width: 100%;
  }
  body .user-menu {
    margin-bottom: 10px; 

  }
  #content .section-online-holding__introduction .title h3 .input .lbx{
    display: none 
  }
  .bootstrap-select.btn-group.select-change-date .dropdown-toggle .filter-option,
  .bootstrap-select.btn-group.select-change-date .dropdown-menu li a span.text,
  .drop-down-list.select-change-date .span-ten-update{
    font-size:20px;
    padding-top:4px;
    padding-bottom:4px;
    line-height: 1.1;
    text-align: center;
    text-transform: none;
  }
  .user-menu__information{
    position: relative;
    .logout-menu{
      font-size: 20px;
      text-transform: uppercase;
      position: absolute;
      top:0;
      right: 0
    }
  }
  #content .title h3 .input .arrlx {
    font-size: 32px;
    position: relative;
    top: 6px;
  }
  #content .title .select-change-date, 
  .drop-down-list.select-change-date {
    width: calc(90% - 50px);
    margin: 0 13px;
  }
  .bootstrap-select.btn-group .dropdown-toggle .filter-option{
    margin-top: 0;
  }
  .drop-down-list.select-change-date .span-ten-update br{
    display: none
  }
  #content .title h3 {
    padding-top: 0;
    color: transparent;
    text-shadow: none;
    font-size: 0;
    padding-bottom: 20px;
  }
  #content .title h3 .input {
    position: static;
    color:#FFF;
    display: block;
    margin: 0;
    width: 100%;
    text-align: center;
    margin-top: 13px;
  }
}
@media screen and (max-width: 500px){ 
  .current-account-table{
    margin-bottom: 8px
  }
}
@media screen and (max-width: 568px){ 
  #content .title {
    padding-top: 85px;
  }
  #content .title h3 {
    //margin-top: 15px;
  }
  /*#content .title h3{
    font-size:40px;
    text-shadow: 0px 2px 0px #000000;
  }*/
  .user-menu .user-menu__list a.user-menu__option {
    font-size:18px;
    margin-top:0
  }
  body .section-online-holding__introduction .title {
    padding-bottom: 0
  }
}

@media screen and (max-width: 450px){ 
  .section-online-holding .tfoot-alt  .tts{
    background-color: #D9E1EF;
    color:#000;
    font-size: 22px;
    top:0
  }
  #content .title .select-change-date, .drop-down-list.select-change-date{
    width:calc(90% - 70px);   
  }
  .user-menu__list_cc{
    overflow: hidden;
    overflow-x:auto;
    padding-bottom: 17px;
  }
  .user-menu__list {
    display: inline-flex;
    flex-wrap: nowrap;
    width: auto;
    margin-top:20px;
    margin-bottom: 0;
    .user-menu__option{
      min-width: 150px;
      font-size:18px !important;
      padding-top: 10px;
      height: 32px !important;
      &:hover:not(.active){
        &:after{
          display: none;
        }
      }   
    }
  }
  .section-online-holding .tfoot-alt{
    padding: 0;
  }
  .section-online-holding .tfoot-alt .tts,
  .section-online-holding .tfoot-alt .tt{
    margin:auto;
    padding: 0.75rem;
    float:none;
    text-align: center;
    width:auto
  }
  .section-online-holding .tfoot-alt .nl{
    display: block
  }

}
@media screen and (max-width: 340px){ 
  .section-online-holding .tfoot-alt .nl{
    display: none
  }
}
@media screen and (max-width: 1100px){  
  body .menu-mobile{
    display: none
  }
}
body.open-rmenu {
  overflow:hidden;
  .rmenu {
    right: 0;
  }
}
.holding-value-table tbody tr,
.holding-value-table thead tr{  
  td, th {
    &.hide-cols-filter{
      display: none !important
    }
  }
}


.filter-row{
  font-family: "Helvetica Condensed", sans-serif;
  background: #FFF;
  padding:5px 12px 7px;
  margin-top:15px;
  user-select:none;
  overflow: hidden;
  color:#4470b5;
  border:1px solid #4470b5;
  border-bottom: 0; 
  margin-bottom: -2px;
  margin-left: .3px;
  margin-right: 1px;
  position: relative;

  .box-filter{
    &:not(:first-child){
      margin-top: -6px;
    }
    body.loading-data &{
      opacity: .5;
      pointer-events:none
    }
  }

  .loading-fa{
    position: absolute;
    top:5px;
    right: 0;
    z-index: 3;
    height: 100%;
    max-height:38px;
  }
  .fa-spin{
    position: absolute;
    top:50%;
    opacity: 0;
    transition: right .4s, opacity .4s;
    margin-top: -14px;
    font-size:28px;
    right: 6px;
    background-size: #FFF;
    body.loading-data &{
      opacity:1;        
    }
  }
  .lblx{
    padding:12px 0 7px;
    display: inline-block;
    font-weight: bold;
    font-size: 17px;
    min-width:85px;
    text-transform: uppercase;
    position: relative;
    top:2px;
    span{
      display: none;
    }
    @media screen and (max-width: 1100px){  
      padding-left: 0; 
      padding-right:3px;
    }
  }

  .col-filters{
    padding: 11px 5px 8px 8px;
    .lbx{
      margin-left:0;
    }
  }
  .chck{
    font-size: 16px;
    display: inline-block;
    cursor:pointer;
    padding:11px 15px 8px 8px;
    vertical-align: top;
    .txt{
      position: relative;
      top:1px;
      .lbl-aliq{
        display: none
      }
    }
    &.column{
      font-size:14px;
      cursor:move;
      &.sortable-ghost{
      	opacity: .4
      }
      &.sortable-drag{
      	color:#2c384a;
    		.ico{
    			border-color:#2c384a;
    		}
      	&.active{
      		.ico{
      			background: #2c384a;
      		}
      	}
      }
      .txt{
        top:2px;
      }
    }
    &.loading{
      cursor:default;
    }
    &:hover{
      .ico{
        background:#D9E1EF;
        i{
          opacity:.7;         
        }
      }
    }
    &:active,
    &.active{
      .ico{
        color:#FFF;
        background:#4470b5;
        i{
          opacity:1;          
        }
      }
      @media screen and (min-width: 1100px){  
        &:hover:not(:active){
          .ico{
            color:#c5d3e8;
            background:#4470b5;
            i{
              opacity:.7;         
            }
          }
        }
      }
    }
    .ico{ 
      transition:all .2s;
      display: inline-block;
      width:15px;
      vertical-align: top;
      margin-right: 5px;
      height:15px;
      left:-.5px;
      color:#4470b5;
      border:2px solid #4470b5;
      background: #f6f8fb;
      font-size:11px;
      text-align: center;
      i{
        opacity:0;
        transition: opacity .2s;
        top:2px;
        position: relative;
        vertical-align: middle;
      }
    }
  }
}


body.loading-data{
  user-select:none;
  .app-table-extended{
    cusor:defualt !important;
  }
}


.app-table-species{
  .app-table > tbody > tr{
  	@media screen and (max-width: 1023px) {
	  	&:not(.item-hidden):not(.item-no-hover).active,
	  	&:not(.item-hidden):not(.item-no-hover):hover,
	  	&:not(.item-hidden):not(.item-no-hover).render-be{
	  		td{
	  			&.cindex-be{
	  				.num{
	  					left:-10px;
	  				}
	  				.fa{
	  					opacity: 1;
	  					margin-left: 15px;
	  				}
	  			}
	  		}
	  	}
  	}
  	&:not(.item-hidden):not(.item-no-hover).active,
  	&:not(.item-hidden):not(.item-no-hover):hover{
  		cursor:pointer;
  		/*&.item-extended{
  			td{
  				background: #D9E1EF  				
  			}
  		}*/
  		td{
				color:#FFF;  				
  			background:#688bc1;
  			&.cindex-be{
  				.num{
    				left:-10px;
  				}
    			.fa{
    				opacity: 1;
    				margin-left: 15px;
    			}
  			}
  		}
  	}
  }
}

.app-table-extended {
  .app-table tr .hide-cols-filter{
    display:none !important;
  }
  .app-table >thead tr th{
    &:first-child{
      text-align: left;
    }
  }
  .app-table >tbody tr td{
    &:first-child{
      text-align: left;
      text-transform: uppercase
    }
  }
  transition:opacity .1s;
  .item-extended{
    background: #e4eaf4;
  }
  .render-pd,
  .item-extended{
    border-top:0;
    border-bottom:0;
    &.item-closed{
      /*td .pd-table .pd-table-pd{
        opacity: 0;
      }*/
    }
    &:not(.item-hidden){
      /*&:first-child{
        border-top: 1px solid #FFF;
      }*/
    }
    td{
      transition:border .2s;
      border-top: 0 solid #FFF;
      border-bottom: 2px solid #FFF;
      padding:0 !important;
      .pd-table{ 
        overflow: hidden;
        max-height:80px;
        transition:max-height .2s;
      }

      .pd-table-pd{
        padding:13px 8px 10px;
      }

      //@for $i from 0 through 20 {
      //  &:nth-child(#{$i}) {
      //    .pd-table-pd{
      //      transition: opacity .2s linear $i * .03s;
      //    }
      //  }
      //}
    }
    &.item-hidden{
      td{
        border-top: 0px solid transparent;
        border-bottom: 0px solid transparent;
      }
      .pd-table{
        max-height: 0;
      }
    }
  }
}

.app-table-extended{
  &.app-table-species{
    margin-bottom: 15px;
    table thead tr th.cindex-specie{
      width:14%;
      max-width:14%;
    }
    table thead tr th.cindex-price{
      width:10%;
      max-width:10%;
    }
    table thead tr th.cindex-be{
      width:8%;
      max-width:8%;
    }
    table tbody tr .cindex-be{
    	position: relative;
    	.num{
    		position: relative;
    		transition:left .2s;
    		left:0;
    	}
    	.fa{
    		transition:margin-left .2s, opacity .3s;
    		position: absolute;
    		background: #FFF;
    		color:#4470b5;
    		padding:4px;
    		border-radius: 100%;
    		margin-left:25px;
    		left:calc(50% - 10px);
    		top:calc(50% - 12px);
    		opacity: 0;
    	}
    }
  }
  &.app-table-cm{
    table thead tr th:first-child{
      width:13%;
      max-width:13%;
    }
    table thead tr th:nth-child(2){
      width:10%;
      max-width:10%;
    }
  }
  &.app-table-bonus{
    table thead tr th:first-child{
      width:auto;
    }
  }
  table thead tr th .lbl-aliq{
    font-size:12px;
    margin-top: -2px;
    display: block;
    padding-bottom: 2px;
    font-weight: 300;
  }
  table tbody tr td.cindex-be{
    text-align: center;
  }
  table thead tr th{
    width:11%;
  }
}



.app-be-box{
	background:#f1f1f1;
  border-top:0;
  border-bottom:0;
  cursor:default;
  &:not(.active){
  	.be-movs{
  		max-height:0 !important;
  	}
  }
  .be-movs{
  	transition:max-height .3s;
  	overflow:hidden;
  	overflow-y:scroll;
  	.be-table{
  		background: #FFF;
  		.loading{
  			background: #22A973;
  			background: #688bc1;
  			color:#FFF;
  			text-align: center;
  			font-size:22px;
  			padding:10px;
		    font-family: "Helvetica Condensed", sans-serif;
		    font-weight: 500;
		    user-select:none;
		    cursor:pointer;
		   	&.end{
		   		cursor:pointer;
		   		&:hover{
		   			opacity: .8;
		   		}
		   		.lbl1{
		   			display: none;
		   		}
		   	}
		   	&:not(.end){
		   		.lbl2{
		   			display: none;
		   		}
		   	}
  		}
  		table{
  			border-collapse: collapse;
  			text-transform: none;
  			text-align: center;
  			display: table;
  			.row-base{
  				display: none !important
  			}
  			.item-negative{
  				td:nth-child(n+5){
  					color:red;
  				}
  				td{
  					background: #f5f5f5 !important;
  				}
  			}
  			tbody td{
  				font-size:14px;
  				text-align: center;
  				color:#1E293C;
					background: #EEE !important;
					.type{
						font-weight: bold;
					}
  			}
  			thead th{
  				text-align: center;  
  				width: auto;
  				padding-top: 10px;
  				background: #686868
  			}
  		}
  		//min-height: 400px;
  	}
  }
  &.active{
    .be-movs{
    	//max-height:700px;
    }
  	> td{
    	border-bottom: 2px solid #FFF;
  	}
  }
	> td{
		border-left:0;
		border-right:0;
    transition:border .2s;
    border-top: 0 solid #FFF;
    border-bottom: 0 solid #FFF;
		padding:0 !important;
	}
}
