@font-face {
	font-family: 'Helvetica';
	src: url('fonts/HelveticaNeueLTStd-Roman.eot');
	src: url('fonts/HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-Roman.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-Roman.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
	font-weight: 400;
}
@font-face {
	font-family: 'Helvetica';
	src: url('fonts/HelveticaNeueLTStd-Bd.eot');
	src: url('fonts/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-Bd.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-Bd.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');
	font-weight: 700;
}
@font-face {
	font-family: 'HelveticaMediumCondensed';
	src: url('fonts/HelveticaNeueLTStd-MdCn.eot');
	src: url('fonts/HelveticaNeueLTStd-MdCn.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-MdCn.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-MdCn.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-MdCn.svg#HelveticaNeueLTStd-MdCn') format('svg');
}
@font-face {
	font-family: 'HelveticaCondensed';
	src: url('fonts/HelveticaNeueLTStd-Cn.eot');
	src: url('fonts/HelveticaNeueLTStd-Cn.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-Cn.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-Cn.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-Cn.svg#HelveticaNeueLTStd-Cn') format('svg');
}
@font-face {
	font-family: 'HelveticaBoldCondensed';
	src: url('fonts/HelveticaNeueLTStd-BdCn.eot');
	src: url('fonts/HelveticaNeueLTStd-BdCn.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-BdCn.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-BdCn.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-BdCn.svg#HelveticaNeueLTStd-BdCn') format('svg');
}
@font-face {
	font-family: 'Helvetica Condensed';
	src: url('fonts/HelveticaNeueLTStd-MdCn.eot');
	src: url('fonts/HelveticaNeueLTStd-MdCn.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-MdCn.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-MdCn.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-MdCn.svg#HelveticaNeueLTStd-MdCn') format('svg');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Helvetica Condensed';
	src: url('fonts/HelveticaNeueLTStd-Cn.eot');
	src: url('fonts/HelveticaNeueLTStd-Cn.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-Cn.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-Cn.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-Cn.svg#HelveticaNeueLTStd-Cn') format('svg');
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-family: 'Helvetica Condensed';
	src: url('fonts/HelveticaNeueLTStd-BdCn.eot');
	src: url('fonts/HelveticaNeueLTStd-BdCn.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-BdCn.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-BdCn.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-BdCn.svg#HelveticaNeueLTStd-BdCn') format('svg');
	font-style: normal;
	font-weight: 700;
}
@font-face {
	font-family: 'Helvetica Condensed';
	src: url('fonts/HelveticaNeueLTStd-BdCnO.eot');
	src: url('fonts/HelveticaNeueLTStd-BdCnO.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-BdCnO.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-BdCnO.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-BdCnO.svg#HelveticaNeueLTStd-BdCnO') format('svg');
	src:url(fonts/HelveticaNeueLTStd-BdCnO.otf);
	font-style: italic;
	font-weight: 700;
}
@font-face {
	font-family: 'Helvetica Condensed';
	src: url('fonts/HelveticaNeueLTStd-LtCn.eot');
	src: url('fonts/HelveticaNeueLTStd-LtCn.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-LtCn.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-LtCn.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-LtCn.svg#HelveticaNeueLTStd-LtCn') format('svg');
	font-style: normal;
	font-weight: 300;
}
@font-face {
	font-family: 'Helvetica Condensed';
	src: url('fonts/HelveticaNeueLTStd-HvCn.eot');
	src: url('fonts/HelveticaNeueLTStd-HvCn.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-HvCn.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-HvCn.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-HvCn.svg#HelveticaNeueLTStd-HvCn') format('svg');
	font-style: normal;
	font-weight: 800;
}
@font-face {
	font-family: 'Helvetica Condensed';
	src: url('fonts/HelveticaNeueLTStd-BlkCn.eot');
	src: url('fonts/HelveticaNeueLTStd-BlkCn.eot?#iefix') format('embedded-opentype'),
	     url('fonts/HelveticaNeueLTStd-BlkCn.woff') format('woff'),
	     url('fonts/HelveticaNeueLTStd-BlkCn.ttf') format('truetype'),
	     url('fonts/HelveticaNeueLTStd-BlkCn.svg#HelveticaNeueLTStd-BlkCn') format('svg');
	font-style: normal;
	font-weight: 900;
}
