



@media print {
	html, body{
	  max-width: 1000px;	
	  width:100%;
  	margin: auto;	
		padding:0
	}
	@page {
		size: A3;
		margin: 2cm 1cm;
	    @bottom-left {
	      content: counter(page) "/" counter(pages);
	    }
	    @bottom-right {
	      content: '';
	    }
	    @top-left {
	      content: '';
	    }
	}
  
	body.print-version{
		.dropdown-menu.open{
			display: none !important
		}
		.container{
	    width: 100%;
	    max-width: none;
	    padding:0;
	 	}
	}
}
.print-version{
  margin: auto;
	padding:20px 0;
	*{
    -moz-transition: none!important;
    -webkit-transition: none!important;
    -o-transition: 0 !important;
    transition: none!important;
	}
	background: #FFF;
	.especificaciones-de-cuenta-item{
		.ttxtx {
			display: block;
			margin-bottom: 5px
		}
		.custom-control.custom-checkbox .custom-control-description{
			font-size:12px
		}
		.custom-control.custom-checkbox {
	    padding-left: 27.7px;
	    margin-left: 0;
	    margin-right: 32px;
	    margin-bottom: 5px;
		}
		.stage-5__observations {
	    margin-bottom: 10px;
	  }
	  .stage-5__statement{
	  	font-size:12px;
	  	margin-top: 10px;
	  }
		.form-group.form-group-textarea textarea{
			height: 100px !important
		}
	}
	.authoper{
		padding-top:25px;
		padding-bottom:25px;
	}
	.stage-5__statement{
		background: transparent;
		color:#000;
		border:2px solid #000;
	}
	.lagals{
		page-break-before:always;
		.stage-content-cc{
			p{
				font-size:11px;
				line-height: 13px;
			}
			h3{
				font-size:14px;
				line-height: 18px;
			}
		}
	}
	.page-break{
		page-break-before:always;
	}
	.stage-7{
		page-break-before:always;
	}
	.account-details,
	.account-details .stage,
	.account-details .stage .stage-content-cc,
 	.aper-account{
    flex-wrap: nowrap;
    flex-direction:row;
    flex-grow:0;
    flex-shrink:1;
  	max-width: none;
 	  width: 100%;
 	  margin:0 auto;
  	flex: 0 1 auto;
		display: block;
	}
	.account-details {
		background: transparent;
		.auttt{
			padding-top:10px;
			padding-bottom:20px;
			clear: both;
			label{
				display: block;
				margin-bottom:5px
			}
			.col-md-6{
		    flex: 0 0 50%;
		    max-width: 50%;
			}
			.form-group textarea{
				height: 100px;
			}
		}
		.DO_CTB_X.element-base{
    	padding-top:0; 
    	margin-top:0; 
    	border-top:0;
			.stage-information{
				display: flex;
				margin-bottom: 20px;
			}
		}
		.DO_CTB_X{
    	border-top: 1px dotted #666;
    	margin-top:20px; 
    	padding-top:20px; 
			.stage-information{
				display: none;
			}
		}

		.lagals,
		.DO_CTB_X,
		.especificaciones-de-cuenta{
		  .col-md-1 {
		    -webkit-flex: 0 0 8.33333%;
		        -ms-flex: 0 0 8.33333%;
		            flex: 0 0 8.33333%;
		    max-width: 8.33333%;
		  }
		  .col-md-2 {
		    -webkit-flex: 0 0 16.66667%;
		        -ms-flex: 0 0 16.66667%;
		            flex: 0 0 16.66667%;
		    max-width: 16.66667%;
		  }
		  .col-md-3 {
		    -webkit-flex: 0 0 25%;
		        -ms-flex: 0 0 25%;
		            flex: 0 0 25%;
		    max-width: 25%;
		  }
		  .col-md-4 {
		    -webkit-flex: 0 0 33.33333%;
		        -ms-flex: 0 0 33.33333%;
		            flex: 0 0 33.33333%;
		    max-width: 33.33333%;
		  }
		  .col-md-5 {
		    -webkit-flex: 0 0 41.66667%;
		        -ms-flex: 0 0 41.66667%;
		            flex: 0 0 41.66667%;
		    max-width: 41.66667%;
		  }
		  .col-md-6 {
		    -webkit-flex: 0 0 50%;
		        -ms-flex: 0 0 50%;
		            flex: 0 0 50%;
		    max-width: 50%;
		  }
		  .col-md-7 {
		    -webkit-flex: 0 0 58.33333%;
		        -ms-flex: 0 0 58.33333%;
		            flex: 0 0 58.33333%;
		    max-width: 58.33333%;
		  }
		  .col-md-8 {
		    -webkit-flex: 0 0 66.66667%;
		        -ms-flex: 0 0 66.66667%;
		            flex: 0 0 66.66667%;
		    max-width: 66.66667%;
		  }
		  .col-md-9 {
		    -webkit-flex: 0 0 75%;
		        -ms-flex: 0 0 75%;
		            flex: 0 0 75%;
		    max-width: 75%;
		  }
		  .col-md-10 {
		    -webkit-flex: 0 0 83.33333%;
		        -ms-flex: 0 0 83.33333%;
		            flex: 0 0 83.33333%;
		    max-width: 83.33333%;
		  }
		  .col-md-11 {
		    -webkit-flex: 0 0 91.66667%;
		        -ms-flex: 0 0 91.66667%;
		            flex: 0 0 91.66667%;
		    max-width: 91.66667%;
		  }
		  .col-md-12 {
		    -webkit-flex: 0 0 100%;
		        -ms-flex: 0 0 100%;
		            flex: 0 0 100%;
		    max-width: 100%;
		  }
		}
		.mt-datee{
			position: relative;
			margin-top:-10px !important;
			margin-bottom:8px !important;
			padding-left: 145px;
			label{
				position: absolute;
				top:10px;
				left:0;
			}
		}
		.stage{
 	 	  padding:0;
 	 	  min-height: 0;
		}
		.firm-bank-accx.real_only_print,
		.firm-bank-accx{
			display: block !important;
			.form-group textarea,{
				height: 100px;
			}
			.form-group:first-child{
				margin-left:0;
			}
			.form-group{
				margin-left:20px;
				margin-right: 0;
		   	width:calc(50% - 10px);
		   	float:left
			}
		}
		.row-firmx{
			display: flex !important;
			.form-group textarea,{
				height: 100px;
			}
			.col-md-6{
		    flex: 0 0 50%;
		    max-width: 50%;
			}
		}
		.stage-7{
			hr{
				display: none
			}
			.firm-bank-acc{
				max-width: 41.66667%
			}
		}
		.stage-6{
			hr{
				display: none
			}
			.firm-bank-acc{
				max-width: 41.66667%
			}
		}
		.row-firmx,
		.natural-exclusive,
		.stage .flex-c-sm,
		.stage-nbi{
			page-break-inside: avoid;			
		}
		.stage-information{
	    margin-bottom: 12px;
	    color:#000;
	    i{
			  margin-bottom: 0;
			  font-size: 24px;
			  margin-left:3px;
			  margin-right: 4px;
			  position: relative;
			  top: 3px;
	    }
		}
		.working-activity-information{
			.ttt-c2a{
				.form-group:last-of-type {
			    width:calc(25% - 12.5px)
				}
			}
			.ttt-c2{
				.form-group:last-of-type {
			    width:calc(50% - 12.5px)
				}
			}
			.ttt-c3{
				.form-group:last-of-type {
			    width:calc(33.33%)
				}
			}
			.ttt-c{
		    flex-direction: row;
		    display: flex !important;
				.form-group{
			    margin-right: 25px;
				}
				.form-group:last-of-type {
			    margin-right: 0;
			    -webkit-flex-shrink: 0;
			    -ms-flex-negative: 0;
			    flex-shrink: 0;
				}
			}
		}
		.title-dates{
			margin-bottom:18px;
			.lxx{
				font-weight: bold;
				font-size:12px;
				margin-right: 5px;
   			color: #1e293c;
			}
			.form-group{
				margin-right: 10px;
				input{
					font-size:14px;
				  display:inline-block; 
					width: 120px;
	  	  	color: #000 !important;
				}
			}
			.form-gg{
				display:inline-block; 
				margin-left:20px
			}
		}
		.stage-2__3-items-row-alt .form-group:last-of-type{
			width:40%
		}
		.custom-radios-box{
			display: none;
		}
		.custom-control.custom-radio{
			padding-left:24px;
   	  margin-bottom: 0;
		}
		.custom-control.custom-radio .custom-control-indicator{
			width:15px;
			height: 15px;
		}
		.custom-control.custom-radio .custom-control-description{
			font-size:12px;
			margin-top: 0
		}
		.form-group{
  		margin-bottom: 12px;
  		margin-right:20px;
		}
		.form-group label{
			font-weight: bold;
			font-size:12px;
			margin-bottom: 3px;
		}
		.form-group textarea,
		.form-group select,
		.form-group input,
		.bootstrap-select{
  	  color: #000 !important;
   	  border-bottom: 1px solid #CCC !important;
		}
		.form-group .bootstrap-select .dropdown-toggle{
    	height: 25px;
			padding-left:0 !important
		}
		.form-group .bootstrap-select .filter-option{
  	  color: #000 !important;
    	font-size: 14px;
		}
		.form-group textarea,
		.form-group select,
		.form-group input{
			width:100%;
			border:0;
    	height: 25px;
    	font-size: 14px;
    	padding-left:0;
			padding-right:0;
			border-radius:0 !important;
		}
		.form-group.form-group-textarea{
			position: relative;
			padding-bottom:22px;
			label{
				position: absolute;
				left:0;
				bottom:0;
				right: 0;
				text-align: center;
				display: block;
				margin-bottom: 0
			}
			textarea{
				height: 100px;
				border:1px solid #999 !important
			}
		}
		.ffchangedom{
			padding-top:35px;
			.form-group textarea{
				height: 140px;
			}
		}
		.form-group textarea{
			height: 70px;
			resize:none;
		}
		.stage-content-cc{
			padding:0;
		}
	}
	// .no-hover{
	// 	display: none
	// }
	.data_client{
		padding-top:5px;
	}
	.base-title-st{
		overflow:hidden;
		.logo-eb{
			text-align: right;
		}
		.info-tt{
			padding-top:5px;
			float:left;
			.data_account-g{
				margin-top: 10px;
			}
			h1{
				font-size:28px;				
			}
		}
	}
}

.form-stages {

	.panel-transparent {
		width: 58%;
		color: white;
		@media only screen and (max-width: 1024px) {
			width: 100%;
			.btn {
				margin-bottom: $spacer*2;
			}
		}
		.stage-title {
			font-weight: 900;
			font-size: 1.3rem;
			letter-spacing: 1.1px;
			font-family: $font-family-condensed;
			text-shadow: 2px 2px 2px rgba(0,0,0,.5);
		}
		.stage-subtitle {
			font-weight: 100;
			font-size: 1rem;
			line-height: 1.2rem;
			text-shadow: 2px 2px 2px rgba(0,0,0,.5);
		}
		.stage-text {
			font-weight: 100;
			font-size: .84rem;
			line-height: 1.05rem;
		}
		.item-adjunt {
			// text-transform: uppercase;
			font-size: 1.2rem;
			display: inline-block;
			padding: 0 $spacer*2;
			border-right: 1px solid $brand-primary;
			&:last-child {
				border-right: none;
			}
		}
	}
	.btn-sm {
		width: 100%;
		padding-top: $spacer;
		padding-bottom: $spacer;
	}
	.stage {
		//max-width: 721px;
		margin: auto;
		display: none;
		@extend .flex-c;
		.col-md-12 {
			@media only screen and (max-width: 1024px) {
				padding: 0 $spacer*2.5;
			}
		}
	}
	.stage.active{
		display: block;
	}
}

.progress-column {
	background-color: #009CDF;
	padding-top: $spacer*3;
	margin-bottom: $spacer*3.3;
	@media only screen and (max-width: 1024px) {
		margin-bottom: 0;
	}
	.progress-circle {
		position: relative;
		&__text {
			position: absolute;
			top: 35%;
			left: 25%;
			font-family: $font-family-condensed-final;
			font-weight: 900;
			font-size: $font-size-base;
			line-height: 0.9;
			color: $white;
			text-align: left;
			span {
				font-size: $font-size-base*2.75;
			}
		}
		width: $spacer*15.8;
		height: $spacer*15.8;
		margin: auto;
		margin-bottom: $spacer*3.3;
		border-radius: 50%;
		background:url(images/aperturadecuenta-background.jpg) transparent top center no-repeat fixed;
		background-size:cover;
	}
	&__stages {
		@extend .flex-r;
		max-width: $spacer*17.7;
		align-items: center;
		margin-left: $spacer;
		color: $white;
		font-family: $font-family-condensed-final;
		font-weight: 500;
		font-size: $font-size-base;
		line-height: 1.2;
		margin-bottom: $spacer;
		span {
			margin-left: $spacer*.4;
		}
		&:before {
			content: "\f0a9";
			font-family: FontAwesome;
			color: $white;
			font-size: $font-size-base*1.48;
			margin-bottom: $spacer*.5;
			margin-right: $spacer*.5;
		}
		&_on {
			&:before {
				color: $teal;
			}
		}
		&_visited {
			color: $teal;
			&:before {
				color: $teal;
			}
		}
	}
}

$account-opening-form-border: #999999;
$account-opening-form-font-color: #666666;

.btn-account-opening {
	@include button-variant($account-opening-form-border, $white, $account-opening-form-border);
	&:focus {
		box-shadow: none;
	}
}

.account-opening-form {
	&__buttons {
		@extend .flex-r;
		justify-content: flex-end;
		margin-top: $spacer*2;
		a {
			@extend .flex-c;
			justify-content: center;
			align-items: center;
			font-family: $font-family-condensed-final;
			font-weight: 500;
			font-size: $font-size-base*.875;
			letter-spacing: 0.026em;
			text-transform: uppercase;
			border-radius: $border-radius-lg;
			height: $spacer*2.6;
			align-self: center;
			width: auto;
			padding-left: $spacer*1.6;
			background-color: $blue;
			padding-right: $spacer*1.6;
			color: $white;
			margin-right: $spacer*1.6;
			&:last-of-type {
				margin-right: 0;
			}
			&:hover {
				background-color: darken($blue,15%);
			}
		}
		&_add {
			justify-content: flex-start;
			a {
				background-color: $teal;
				&:hover {
					background-color: darken($teal,15%);
				}
			}
		}
	}
}

.stage-information {
	@extend .flex-r, .col-md-12;
	margin-bottom: 0;
	align-items: center;
	background-color: $blue;
	border-bottom: $spacer*.1 solid $teal;
	height: $spacer*4.5;
	color: $white;
	font-size: $font-size-base;
	font-weight: 500;
	font-family: $font-family-condensed-final;
	i {
		margin-bottom: $spacer*.5;
		font-size: $font-size-base*1.48;
		margin-left: $spacer*1.2;
		margin-right: $spacer*1.2;
    position: relative;
    top: 2px;
	}
}

.stage-2{
	background-color: $white;
	padding: 0;
	color: $teal;
	&.stage {
		min-height: $spacer*25.7;
		margin-top: 0;
		margin-bottom: $spacer*3.3;
	}
	.form-group {
		width: 100%;
		margin-bottom: $spacer*1.6;
		label, input, textarea {
			@extend .full-width;
			display: flex;
		}
		textarea {
			resize: none;
			border-radius: $border-radius-lg;
			font-size: $font-size-base*.875;
			border: $spacer*.1 solid $account-opening-form-border;
			padding-left: $spacer;

		}
		label {
			margin-bottom: $spacer*.4;
		}
		input {
			padding-left: $spacer;
			font-size: $font-size-base*.875;
			height: $spacer*2.7;
			border-radius: $border-radius-lg;
			border: $spacer*.1 solid $account-opening-form-border;
		}
		::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			color: $account-opening-form-font-color;
		}
		:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: $account-opening-form-font-color;
		}
		::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: $account-opening-form-font-color;
		}
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: $account-opening-form-font-color;
		}
		.bootstrap-select {
			.filter-option {
				text-transform: capitalize;
				font-size: $font-size-base*.875;
				color: $account-opening-form-font-color;
			}
			.dropdown-toggle {
				padding: 0 0 0 $spacer;
				height: $spacer*2.7;
				&:after {
					border: none;
					position: absolute;
					right: $spacer*1;
					content: "\f078";
					font-family: FontAwesome;
					color: $account-opening-form-border;
					font-size: $font-size-xs;
					font-style: normal;
					height: auto;
					width: auto;
					margin-top: $spacer*.3;
				}
			}
			.dropdown-menu {
				font-size: $font-size-base*.875;
				color: $account-opening-form-font-color;
				li {
					a {
						padding-left: $spacer;
					}
					&:hover {
						background-color: darken($white,10%);
					}
				}
			}
		}
	}
	.custom-control.custom-checkbox {
		padding-left: $spacer*1.87+$spacer*1;
		margin-left: 0;
		margin-right: $spacer*3.2;
		margin-bottom: $spacer*2.4;
		&:last-of-type {
			margin-right: 0;
		}
		.custom-control-input {
			&:checked {
				~ .custom-control-indicator {
					box-shadow: none;
					outline: none;
					background-color: $white;
					color: $teal;
					background-size: 75% 75%;
					background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$teal}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");
				}
			}
			&:focus {
				~ .custom-control-indicator {
					box-shadow: none;
					outline: none;
				}
			}
		}
		.custom-control-indicator {
			height: $spacer*1.87;
			width: $spacer*1.87;
			border-radius: 50%;
			background-color: $white;
			border: $spacer*.1 solid $account-opening-form-border;
		}
		.custom-control-description {
			@extend .flex-r;
			line-height: 1.5;
			align-items: center;
			margin-top: $spacer*.4;
			color: $teal;
			font-size: $font-size-base*.875;
		}
	}
	.custom-control.custom-radio {
		padding-left: $spacer*1.87+$spacer*1;
		margin-left: $spacer*4.2;
		margin-right: 0;
		margin-bottom: $spacer*1.6;
		&:first-of-type {
			margin-left: 0;
		}
		.custom-control-input {
			&:checked {
				~ .custom-control-indicator {
					box-shadow: none;
					outline: none;
					background-color: $teal;
				}
			}
			&:focus {
				~ .custom-control-indicator {
					box-shadow: none;
					outline: none;
				}
			}
		}
		.custom-control-indicator {
			height: $spacer*1.87;
			width: $spacer*1.87;
			background-color: $white;
			border: $spacer*.1 solid $account-opening-form-border;
		}
		.custom-control-description {
			@extend .flex-r;
			align-items: center;
			margin-top: $spacer*.4;
			color: $teal;
			font-size: $font-size-base*.875;
		}
	}
	&__3-items-row {
		@extend .flex-r;
		@media only screen and (max-width: 1024px) {
			flex-direction: column!important;
		}
		.form-group {
			width: 100%;
			margin-right: $spacer*3.6;
			&:last-of-type {
				margin-right: 0;
				flex-shrink: 0;
				width: $spacer*24.6;
				@media only screen and (max-width: 1024px) {
					width: 100%;
				}
			}
		}
	}
	&__2-items-row {
		@extend .flex-r;
		@media only screen and (max-width: 1024px) {
			flex-direction: column!important;
		}
		.form-group {
			width: 100%;
			margin-right: $spacer*3.6;
			&:last-of-type {
				margin-right: 0;
				flex-shrink: 0;
				width: $spacer*19;
				@media only screen and (max-width: 1024px) {
					width: 100%;
				}
			}
		}
	}
}

.stage-3 {
	@extend .stage-2;
	&.stage {
		min-height: $spacer*25.7;
		margin-top: 0;
		margin-bottom: $spacer*3.3;
	}
	.form-group {
		width: 100%;
	}
	.legal-exclusive {
		.form-group {
			margin-right: $spacer*3.2;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	&__2-items-row {
		@extend .flex-r, .flex-c-sm;
		.form-group {
			&:last-of-type {
				margin-left: $spacer*3.6;
				width: $spacer*39.5;
				flex-shrink: 0;
				@media only screen and (max-width: 1024px) {
					margin-left: 0;
					width: 100%;
				}
			}
		}
	}
	.account-opening-form__buttons {
		margin-top: auto;
	}
}

.stage-4 {
	@extend .stage-2;
	&.stage {
		min-height: $spacer*25.7;
		margin-top: 0;
		margin-bottom: $spacer*3.3;
	}
	.form-group {
		width: 100%;
		margin-right: $spacer*3.2;
		&:last-child {
			margin-right: 0;
		}
	}
	.input-lt {
		margin-left: $spacer;
		&:first-of-type {
			margin-left: 0;
		}
	}
	.spacer-block {
		margin-top: $spacer*3.2;
		padding-top: $spacer*3.2;
		border-top: $spacer*.1 solid $account-opening-form-border;
	}
}

.stage-5 {
	@extend .stage-2;
	label {
		display: flex;
		margin-bottom: $spacer*1.2;
	}
	.form-group {
		width: 100%;
		margin-right: $spacer*3.2;
		&:last-child {
			margin-right: 0;
		}
	}
	.custom-control.custom-checkbox {
		padding-left: $spacer*1.87+$spacer*1;
		margin-left: 0;
		margin-right: $spacer*3.2;
		margin-bottom: $spacer*1.6;
		&:last-of-type {
			margin-right: 0;
		}
		.custom-control-input-round {
			&:checked {
				~ .custom-control-indicator {
					box-shadow: none;
					outline: none;
					background-color: $teal;
					background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E"), "#", "%23")
				}
			}
			&:focus {
				~ .custom-control-indicator {
					box-shadow: none;
					outline: none;
				}
			}
		}
		.custom-control-indicator {
			height: $spacer*1.87;
			width: $spacer*1.87;
			border-radius: 50%;
			background-color: $white;
			border: $spacer*.1 solid $account-opening-form-border;
		}
		.custom-control-description {
			@extend .flex-r;
			align-items: center;
			margin-top: $spacer*.4;
			color: $teal;
			font-size: $font-size-base*.875;
		}
	}
	&__observations {
		color: $account-opening-form-font-color;
		font-size: $font-size-xs;
		margin-bottom: $spacer*1.6;
		line-height: 1.17;
	}
	&__statement {
		font-family: $font-family-base;
		padding: $spacer*.8 $spacer;
		background-color: $teal;
		color: $white;
		line-height: 1.17;
		margin-bottom: $spacer*3;
	}
}

.stage-6 {
	@extend .stage-2;
	&.stage {
		min-height: $spacer*25.7;
		margin-top: 0;
		margin-bottom: $spacer*3.3;
	}
	.form-group {
		width: 100%;
		margin-right: $spacer*3.2;
		&:last-child {
			margin-right: 0;
		}
	}
	.bank-account-information {
		border-bottom: $spacer*.1 solid $account-opening-form-border;
		padding-bottom: $spacer*3;
		margin-bottom: $spacer*3;
	}
}

.stage-7 {
	@extend .stage-2;
	label {
		display: flex;
		margin-bottom: $spacer*1.2;
	}
	&.stage {
		min-height: $spacer*25.7;
		margin-top: 0;
		margin-bottom: $spacer*3.3;
	}
	.form-group {
		width: 100%;
		margin-right: $spacer*3.2;
		&:last-child {
			margin-right: 0;
		}
	}
	.custom-control.custom-checkbox {
		padding-left: $spacer*1.87+$spacer*1;
		margin-left: 0;
		margin-right: $spacer*3.2;
		margin-bottom: $spacer*.8;
		&:last-of-type {
			margin-right: 0;
		}
		.custom-control-input {
			&:checked {
				~ .custom-control-indicator {
					box-shadow: none;
					outline: none;
					background-color: $white;
					color: $teal;
					background-size: 75% 75%;
					background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$teal}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");
				}
			}
			&:focus {
				~ .custom-control-indicator {
					box-shadow: none;
					outline: none;
				}
			}
		}
		.custom-control-indicator {
			height: $spacer*1.87;
			width: $spacer*1.87;
			border-radius: 50%;
			background-color: $white;
			border: $spacer*.1 solid $account-opening-form-border;
		}
		.custom-control-description {
			@extend .flex-r;
			line-height: 1.5;
			align-items: center;
			margin-top: $spacer*.4;
			color: $teal;
			font-size: $font-size-base*.875;
		}
	}
}

.stage-8 {
	@extend .stage-2;
	&.stage {
		min-height: $spacer*25.7;
		margin-top: 0;
		margin-bottom: $spacer*3.3;
	}
	.form-group {
		width: 100%;
		margin-right: $spacer*3.2;
		&:last-child {
			margin-right: 0;
		}
	}
	&__text {
		margin-top: $spacer*3.2;
		margin-top: 32px;
	    max-height: 480px;
		overflow-x: hidden;
	    overflow-y: scroll;
	    line-height: 20px;
	    font-size: 14px;
	}
}
.stage-10,
.stage-9 {
	@extend .stage-2;
	&.stage {
		min-height: $spacer*25.7;
		margin-top: 0;
		margin-bottom: $spacer*3.3;
	}
	.form-group {
		width: 100%;
		margin-right: $spacer*3.2;
		&:last-child {
			margin-right: 0;
		}
	}
	color: $teal;
	line-height: 1.5;
	ul {
		li {
			@extend .flex-r;
			&:before {
				content: '\f061';
				font-family: FontAwesome;
				margin-right: $spacer;
				margin-left: $spacer*2;
			}
		}
	}
}
.aper-account {
	min-height: 600px;
	position: relative;
	margin-left: 0;
	margin-right: 0;
}
.stage-bg {
	opacity: 0;
    background: #4470b5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 33px;
    z-index: -1;
	background: linear-gradient(270deg, #4470b5, #009CDF);
	background-size: 400% 400%;

	-webkit-animation: AnimationName 1s ease infinite;
	-moz-animation: AnimationName 1s ease infinite;
	animation: AnimationName 1s ease infinite;
}


@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.form-delete {
    background: transparent;
    font-weight: 900;
    color: gray;
    opacity: .7;
    float: right;
    border: 0;
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
	transition: .3s opacity;
	&:hover {
		color: $red;
		opacity: 1;
	}
}

.duplicate-element {
	position: relative;
	border-top: 1px solid gray;
	padding-top: 15px;
	
}

.only_print {
	display: none;
}
.real_only_print {
	display: none !important;
}
.account-details {
	.only_print {
		display: inherit !important;
	}	
	.only_print.real_only_print {
		display: inherit !important;
	}	
}
.stage-information{
	.account-opening-form__buttons{
		float:right;
    position: absolute;
    right:15px;
    top: 9px;
    margin: 0;
		a{
	    background-color: #FFF;
	    color: #4470b5;
   	  margin-left: 10px;
   	  margin-right: 0;
   	  border:1px solid #FFF;
    	padding-left: 15px;
    	padding-right: 15px;
   	  &:hover{
   	  	border:1px solid #1e293c;
   	  	color: #1e293c
   	  }
		}
	}
}
.stage-content-cc{
	padding-top: $spacer*2.2;
	padding-bottom: $spacer*2.2;
}