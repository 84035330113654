.account-details{
	background: white;
	.no-hover {
		background: transparent;
		top:0;left:0;right:0;bottom:0;
		position: absolute;
		z-index: 99999;
	}
	.stage {
		display: block;
	}
	.stage-information {
		background: white;
		color: $gray;
		border-bottom: $gray 1px solid;
		padding: 0;
		.text-uppercase {
			display: none;
		}
	}
	.dropdown-toggle {
		border: none;
	}
	.dropdown-toggle:after,
	.account-opening-form__buttons {
		display: none !important;
	}/*
	.bootstrap-select,
	.form-group textarea,
	.form-group input {
		border-radius:0 !important;
		border: 0;
		color: $gray !important;
		border-bottom: $gray 1px dotted;
	}*/
	&-noting {
		.bootstrap-select * {
			display: none !important;
		}
		.bootstrap-select {
			height: 28px;
		}
	}
	/*@media print {
		select,
	 	.bootstrap-select * {
			box-shadow: none !important;
		}
		.stage {
			page-break-inside: avoid;
		}
		.break-page {
			page-break-after: always;
		}
	}*/
}
.lagals {
	color: $brand-secondary;

	line-height: 14px;
    font-size: 12px;
    h3 {
		line-height: 30px;
    	
    }
}

.btn-primary.btn-print {
    position: fixed;
    display: inline-block;
    bottom: 15px;
    right: 15px;
    z-index: 999999;
	font-size: 17px;
    @media print {
	  display: none;
	}
}
