$rmenu-background: #4470b5;
$rmenu-color: white;
$rmenu-bordercolor: rgba(white, .4);
$rmenu-maxwidth: 400px;
$rmenu-zindex: 31060;

.rmenu {

  font-family: "Helvetica Condensed", sans-serif;
	position: fixed;
	top: 0;
	left: auto;
	bottom: 0;
	right: -100%;
	width: 100%;
	overflow: scroll;
	color: white;
	transition: .3s ease right;
	max-width: $rmenu-maxwidth;
	background: $rmenu-background;
	color: $rmenu-color;
	z-index: $rmenu-zindex;
	padding-bottom: 50px;
	&__ul {
		list-style: none;
		padding: 0;
		li {
			border-bottom: 1px solid $rmenu-bordercolor;
			cursor: pointer;
			.divider {
				// font-size: 22px;
    			font-weight: 700;
    			padding-left: 10px;
    			color: $brand-warning;
			}
			a {
				padding: $spacer $spacer*3;
				display: block;
				color: $rmenu-color;
				position: relative;
				font-size:18px;
			}
			.portal-nav__btn {
				float: left;
    			right: 0;
			}
			.social-network-icons {
				float: right;
			}
			&:first-child {
				padding-bottom: $spacer*4;
			}
		}
	}
	.social-network-icons {
		text-align: center;
		img {
			width: 32px;
		}
	}
	.rmenu-close {
		@extend .fa;
		@extend .fa-times;
		font-size: 24px;
		background-repeat: no-repeat;
	    width: 0px;
	    display: block;
	    height: 12px;
	    transform: rotate(-90deg);
	    top: 30px;
	    left:30px;
    	position: relative;
	}
	.btn-secondary {
		background: $gray-light;
		color: white;
	}
	.lang-box {
		float: right;
		margin-left: 10px;
    	margin-right: -15px;

	}
	.btn-cart {
		padding-top: 5px;
		path {
			fill: white;
		}
		float: right;

	}
	.btn-secondary {
		border: 0 !important;
		&.dropdown-toggle {
			color: white;
			border: 0 !important;
			background: $gray-light !important;
		}
	}
}
.header-fixed #header {
	.rmenu-btn{
    top: 10px;
	}
	.bars {
		&,
		&:before,
		&:after {
			background: #001f3b
		}
	}
}
.rmenu-btn {
	position: absolute;
	top: 20px;
	right: 3px;
    z-index: 1020;
	cursor: pointer;
		height: 30px;

	display: none;

	@media screen and (max-width: 1100px){	
		display: block;
		right: 25px;
	}
	.bars {

		&,
		&:before,
		&:after {
			width: 30px;
			height: 3px;
			content: '';
			background: #FFF;
			display: block;
		    position: relative;
		}
		&:before {
		    top:18px;
		}
		&:after {
			top: 6px;
		}
	}
}

body.open-rmenu {
	overflow:hidden;
	.rmenu {
		right: 0;
	}
}
