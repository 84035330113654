$user-menu-name: #0099cc;

.user-menu {
  @extend .flex-r;
  font-family: $font-family-condensed-final;
  font-weight: 500;
  margin-bottom: 30px;
  letter-spacing: 0.026em;
  margin-top: -15px;
  &.user-menu-producer.user-menu-producer-in{
  	margin-bottom: 50px;
  }
  &-producer:not(.user-menu-producer-in){
  	margin-bottom: -80px;
		position: relative;
		top:10px;
		#content &.user-menu + h3{
			padding-bottom: 30px;
	  	@media only screen and (max-width: 1100px) {
				padding-bottom: 20px;
	  	}
	  	@media only screen and (max-width: 700px) {
   	  	font-size: 40px;
   	  	color: #FFF;
   	  	text-shadow: 0px 3px 0px #000000;
   	  }
		}
	  @media only screen and (max-width: 1100px) {
	  	margin-bottom: 0;
	  }
  	.user-menu__list_cc{
  		margin-top: 90px;
  		position: relative;
  		top:-35px;
  		margin-right: 0;
		  @media only screen and (max-width: 1100px) {
		  	margin-top: 0px;
		  	top:0;
		  }
		  @media only screen and (max-width: 919px) {
		  	top:25px;
		  }
  	}
  	@media only screen and (max-width:800px) {
   	  margin-top: 5px;
   	  margin-bottom: 30px;
  		.user-menu__list{
  			border:0;
  		}
  		.user-menu__information{
  			height: 0
  		}
  	}
  }
  @media only screen and (max-width: 1100px)   and (min-width:920px){
  	.user-menu__list {
  		font-size: 18px;
  		top: 44px;
  		position: relative;
  	}
  }
  @media only screen and (max-width: 1100px) {
		&.user-menu-producer.user-menu-producer-in{
			margin-top: 0;
  		height: 70px;
  		.client-list{
  			display: block;
  			max-width: 370px;
  		}
			.user-menu__list_cc{
				margin-top: 10px;
			}
			.user-menu__list {
				border-bottom: 0;
				top:15px;
   		  position: relative;
			}
		}
	}
  @media only screen and (max-width: 1000px) {
  	margin-bottom: $spacer*2;
		&.user-menu-producer.user-menu-producer-in{
			margin-bottom: 26px;
			.user-menu__list_cc{
				margin-top: 0;
				position: absolute;
				right: 0;
			}
		}
  }
  @media only screen and (max-width: 920px) {
  	&.user-menu-producer.user-menu-producer-in{
  		height: 90px;
  		.submenu-producer{
  			padding-top: 47px;
  		}
  		.user-menu__list_cc{
  			top: 120px;
			}
		}
  }
  @media only screen and (max-width: 768px) {
  	#content &.user-menu-producer-in + h3 {
  		padding-top: 0;
  		color: transparent;
  		text-shadow: none;
  		font-size: 0 ;
  		padding-bottom: 20px;
  	}
  	#content &.user-menu-producer-in + h3 .input {
  		position: static;
  		color:#FFF;
  		display: block;
  		margin: 0;
  		width: 100%;
  		text-align: center;
  		margin-top: 13px;
  	}
  	#content &.user-menu-producer.user-menu-producer-in + h3 .drop-down-list.select-change-date .dropdown-toggle {
  	    padding-top: 6px;
  	    padding-left: 8px;
  	    padding-bottom: 6px;
  	}
  	&.user-menu-producer.user-menu-producer-in{
  		height: 110px;
  		.submenu-producer{
  			padding-top: 20px;
  			border-bottom: 0;
  			margin:0 20px;
  			min-width: auto;
  			width: auto;
  		}
  		.user-menu__list_cc{
  			right: 20px;
  			top: 40px;
  			width: 280px;
  		}
		}
  }
  @media only screen and (max-width: 568px) {
    flex-direction: column!important;
  }
  .section-documents &{
  	margin-bottom: 8px;
  }
  .submenu-producer{
  	font-size: 18px;
    padding-top: 36px;
  }
	@media only screen and (max-width: 568px) {
		&:not(.user-menu-producer-in){
			padding-top: 0;
			margin-top: 0 !important;
	    margin-bottom: -14px !important;
			.user-menu__my-account{
				min-height: 0;
				line-height: 1;
			}
			.user-menu__information{
				margin-top: 0 !important;
			}
		}
		&.user-menu-producer-in{
			margin-bottom: -5px !important;
			margin-top: 30px !important;
		}
	}
	@media only screen and (max-width: 678px) {
		&.user-menu-producer-in{
			.client-list{
				max-width: none !important;
				width: 100%;
			}
			.submenu-producer,
			.user-menu__list{
				a{
					&:after{
						left:0 !important
					}
				}
			}
		}
	}
	@media only screen and (max-width: 544px) {
		&.user-menu-producer.user-menu-producer-in{
			margin-top:10px !important;
			height:170px;
			.user-menu__list_cc{
				width: 100%;
				position: relative;
				right: 0;
				top: -50px;
				order: -1;
				max-width: none;
			}
			.user-menu__information{
				margin-bottom: 50px !important;
			}
			.user-menu__list{
				top: 0;
				position: relative;
				display: flex;

				a{
					width:50%;
					text-align: center;
					display:block;
				}
			}
		}
		&:not(.user-menu-producer-in){
			.user-menu__list_cc{
				top: 40px !important;
				margin: 0;
				display: block;
				max-width: none;
				width: 100%;
				height: 100px;
				position: relative;
			}
			.user-menu__list{
				position: static;
				width: 100%;
				top: 0;
				padding: 0;
				margin: 0;
			}
		}
	}
	@media only screen and (max-width: 450px) {
		&:not(.user-menu-producer-in){
			.user-menu__list_cc{
				top:30px !important
			}
			.user-menu__list{
				a{
					min-width: 50%;
					text-align: center;
					&:after{
						left:0;
					}
				}
			}
		}
	}
  &__list_cc{
  	margin: auto;
  	//margin-right: 0;
  	margin-top: 40px;
    font-size: 18px;
    max-width:calc(50% - 15px);
  }
  &__information {
    @extend .flex-r;
  	height: 80px;
    @media only screen and (max-width: 568px) {
      justify-content: center;
    }
  }
  &__list {
    @extend .flex-r;
    margin-left: auto;
    @media only screen and (max-width: 568px) {
      margin-left: 0;
    }
  }
  &__name {
    font-size: $font-size-base*1.125;
    color: $user-menu-name;
    line-height: $font-size-base*1.3125;
    margin-left: $spacer*.5;
  }
  &__my-account {
    @extend .flex-c;
    line-height: $font-size-base*1.3125;
    &:after {
      content: '';
      width: $spacer*1.2;
      background-color: $white;
      height: $spacer*.3;
    }
  }
  &__option {
    @extend .flex-c;
    height: 1em;
    position: relative;
    margin-right: $spacer*2.5;
    transition: color ease 0.3s;
    @media only screen and (max-width: 1024px) {
      margin-right: $spacer*1.5;
    }
    @media only screen and (max-width: 568px) {
      margin-top: $spacer*1.5;
      margin-right: $spacer*1.5;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &:after {
      position: absolute;
      content: '';
      width: 0%;
      background-color: $white;
      height: $spacer*.3;
      bottom: -$spacer*.5;
      transition: width ease 0.3s;
    }
    &.active {
      &:after {
        width: 100%;
      }
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}
