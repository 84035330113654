* {
	padding: 0;
	margin: 0
}
body {
	font-family: 'Helvetica', sans-serif;
	font-size: 14px;
	background: url(images/logo-blanco.png) center 150px no-repeat #333;
	font-style: normal;
	line-height: 1;
	overflow-x:hidden;
	position: relative;
	cursor: default;
}
::selection { background: rgba(0,0,0,.5); }
img {
	width: auto;
	max-width:100%;
}
ul, ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
img {
	width: auto;
}
a {
	color: inherit;
	transition:all .2s;
	text-decoration:none;
}
a, a:hover, button {
	cursor: pointer
}
*:focus {
	outline: none;
}
.none {
	display: none;
}
.app-cb {
	clear: both
}
.app-lighter, .app-span-lighter {
	font-weight: lighter;
}
.app-medium, .app-span-medium {
	font-family: 'HelveticaMediumCondensed', sans-serif;
}
.app-span-bold, .app-bold {
	font-weight: bold
}
.app-italic, .app-span-italic {
	font-style: italic
}
#header,#content,#prefooter,#footer {
	position: relative;
}
.app-inside {
	margin: auto;
	max-width:1001px;
	z-index:1;
	position: relative;
}
.app-section-gradient {
	position: absolute; left: 0; right: 0; top: 0; bottom: 0;
		background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%);
	z-index:0;
}
/*
.app-section.section-static,
.app-section.section-home{
	background:url(images/background.jpg) transparent top center no-repeat fixed;
	background-size:cover;
}*/

#app-bg{
	position:absolute;
	top:0;
	left:0;
	right: 0;
	bottom:0;
	z-index: -1;
}
#app-bg.img-alt1{
	background:url(images/aranceles-background.jpg) transparent top center no-repeat fixed;
	background-size:cover;
}
#app-bg.img-alt2{
	background:url(images/aperturadecuenta-background.jpg) transparent top center no-repeat fixed;
	background-size:cover;
}
#app-bg.img-alt3{
	background:url(images/aperturadecuenta-background.jpg) transparent top center no-repeat fixed;
	background-size:cover;
}
.app-section.section-bb2{
	background:url(images/aranceles-background.jpg) transparent top center no-repeat fixed;
	background-size:cover;
}
.app-section.section-apertura-de-cuenta{
	background:url(images/aperturadecuenta-background.jpg) transparent top center no-repeat fixed;
	background-size:cover;
}

.app-section.section-contact{
	background:url(images/aperturadecuenta-background.jpg) transparent top center no-repeat fixed;
	background-size:cover;
}

/* --- HEADER --- */

.fancybox-enabled{
	#header{
		padding-right: 17px;
	}
}


#header{
z-index: 2500;
position: fixed;
top:0;
left:0;
right:0;
}
#header .app-inside {
	height: 120px;
}
#header .app-inside .logo img.logo-x{
	position: relative;
	opacity: 0
}
.header-fixed #header .app-inside .logo img{
	height: 30px;
}
#header .app-inside .logo img{
	position: absolute;
	top:0;
	left:0;
	height: 49px;
	z-index: 1;
	image-rendering: -webkit-optimize-contrast;
}
.header-fixed #header .app-inside .logo{
	top:2px;
}
.header-fixed #header .app-inside .logo .logo-hover,
#header .app-inside .logo .logo-basic{
	opacity: 1;
	z-index: 2;
}
.header-fixed #header .app-inside .logo .logo-basic,
#header .app-inside .logo .logo-hover{
	opacity: 0
}
#header .app-inside .logo,
.menu ul li a,
#header div.app-inside .menu{
}
.header-fixed #header div.app-inside .menu{
	padding-top: 10px
}
.header-fixed .menu ul li a{
	color:#727478;
}
.header-fixed .menu ul li.active a,
.header-fixed .menu ul li a:hover{
	color:#213d54;
}
.header-fixed #header .app-inside{
	height: auto
}
.header-fixed #header .top {
  box-shadow: 2px 2px 2px rgba(0,0,0,.2);
	background: #FFF !important;
	height: 35px
}
.cot-cont .cotizacion .indices h1{
margin-bottom: 8px;
clear: both;
margin-left: 19px;
font-size:16px;
font-family: 'HelveticaMediumCondensed', sans-serif;
text-transform: uppercase;
}
#header .top {
	height:85px;
	&.gradient {
		background: rgba(0,0,0,.2);
		background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(75%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0)));
		background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%);
		background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%);
		background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
	}
}

#header .app-inside .logo {
	position:relative;
	float:left;
	width:33%;
	top:10px;
}
#header div.app-inside .menu {
	position:relative;
	float:left;
	width:67%;
	text-align: right;
	padding-top:30px;}

.header-fixed .menu ul li{
  background:url(images/bullet-alt.png) left no-repeat;
}
.menu ul li{
	font-size:14px;
	font-family: 'HelveticaMediumCondensed', sans-serif;
	list-style-type:none;
  background:url(images/bullet.png) left no-repeat;
	color:#FFF;
	position:relative;
	display:inline-block;
}	
.menu ul li:first-child{
    background: none;
}	
.menu ul li a{
	position:relative;
	color:#fff;
	text-transform:uppercase;
	margin-right:4px;
	padding-left:10px;}
	
.menu ul li.active a{
	color:#d8d8d8;} 

.menu ul li a:hover{
	color:#d8d8d8;} 
/* --- //HEADER --- */

/* --- CONTENT --- */
#content .title.x3 h3{
	padding-bottom: 30px
}
#content .title.x3{
	padding-top:50px;
}
#content .title{
	padding-top:116px;
	color:#fff;
}

#content .title a{
	color:#fff;}

#content .title h2{
	font-size:14px;
	letter-spacing:3px;
	padding-bottom:12px;
	margin-bottom:37px;
	background:url(images/rayita.png) left bottom no-repeat;
	font-weight:normal;}

#content .section-error .title h3{
	max-width: none
}
#content .title{
	position: relative;
}
#content .title .fbutton a:hover{
	background: #FFF;
	color:#009cdf;
}
#content .title .fbutton a{
    color: #FFF;
    background: transparent;
    border:2px solid #FFF;
    width: 202px;
    padding: 10px 30px;
    font-size: 22px;
}
#content .title .fbutton{
	position: absolute;
	top:240px;
	right: 100px
}
#content .title h3{
	font-size:66px;
	text-shadow:0px 3px 0px #000000;
	font-weight:normal;
	padding-bottom:50px;
	max-width: 600px
}
.aranceles #content .title h3{
	margin-bottom:58px;
}

#content .content{
	background:#FFF;}

#content .content .fb .fb-inside{

}
#content .content .fb,#content .content .ap,#content .content .inv{
	width:477px;
	height:305px;
	position:relative;
	float:left;
}
#content .content .ap,#content .content .inv{
	width:202px;
	height:245px;
	color:#FFF;
	padding:30px;
	font-size:12px;
}
#content .ap p,#content .inv p{
	font-size:14px;
	line-height: 16px
}
#content .ap h2,#content .inv h2{
	font-size:18px;
	letter-spacing:2px;
	padding-bottom:12px;
	margin-bottom:37px;
	background:url(images/rayita2.png) left bottom no-repeat;
	font-weight:normal;
}
#content .content .ap{
	background:#4470b3;}
#content .content .inv{
	background:#009cdf;}	

#content .content .form{
	width:48%;
	position:relative;
	float:left;
	padding-top:40px;
	color:#002338;
}

.content .form-in{
	padding: 0 17px 20px 120px;}

#content .form-in .error{
	font-size:12px;
	margin-top: 10px;
	margin-bottom: 10px
}
#content .form-in select,
#content .form-in input,
#content .form-in textarea{
	box-sizing: border-box;
	width:315px;
	background:#ebebeb;
	color:#3e3b4c;
	border:none;
	resize:none;
	padding:6px;
	font-family: 'HelveticaCondensed', sans-serif;
	font-size:20px;
	margin-bottom:8px;
}

#content .form-in textarea{
	height:150px;}

#content .form-in p{
	font-family: 'HelveticaCondensed', sans-serif;
	font-size:26px;
	font-weight: bold;
	margin-bottom:15px;}

#content .form-in .boton{
	width:315px;
	box-sizing: border-box;
	background:#9b9b9b;
	cursor: pointer;
	color:#FFF;}
.cc-quienes-somos p{
	color:#002338;
	margin-bottom: 10px;
	font-size: 14px;
}
.cc-quienes-somos .xbox .xbox2{
	padding: 25px
}
.cc-quienes-somos .xbox p{
	color:#FFF;
	margin-bottom: 10px;
	font-size: 14px;
}
body #content .section-bb2x .cc-quienes-somos .xbox h4{
	color:#FFF;
	font-weight: normal;
  font-family: 'HelveticaMediumCondensed', sans-serif;
  text-transform: uppercase;
	font-size:18px;
	line-height: 20px;
}
.cc-quienes-somos .xbox:nth-child(2n+2){
	margin-right: 0
}
.cc-quienes-somos .xbox{
	float:left;
	width:49%;
	margin:0 0 20px;
	margin-right: 2%;
	background:#4470b3;
}
.cc-quienes-somos .xx3.x5{
    margin-bottom: 10px;
	margin-top: 30px
}
.cc-quienes-somos .xx3{
	font-size:24px;
	margin-bottom: 10px;
	font-family: 'HelveticaCondensed', sans-serif;
	color:#4470b3
}
#content .content .map{
	width:50%;
	position:relative;
	float:left;
	padding-bottom: 30px;
	padding-top:68px;}

#content .content .map iframe{
	width:100%;
	height: 300px
}
#content .map .pre{
	font-family: 'HelveticaCondensed', sans-serif;
	font-size:18px;
	line-height: 22px;
	max-width: 430px;
	padding:27px 0 0 14px;}

#content .section-bb2x .content .aran h1,
#content .section-bb2x .content .aran h2,
#content .section-bb2x .content .aran h3{
  font-size: 36px;
  margin-bottom: 10px;	
	font-family: 'HelveticaMediumCondensed', sans-serif;
}
#content .section-bb2x .content .aran h4,
#content .section-bb2x .content .aran h5,
#content .section-bb2x .content .aran h6{
  font-size: 24px;
  margin-bottom: 8px;	
	font-family: 'HelveticaMediumCondensed', sans-serif;
}
#content .section-bb2x .content .aran ul li {
  display: list-item;
  padding: 0
}	
#content .section-bb2x .content .aran ul{	
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 17px;
}
#content .content .aran p{
  line-height: 1.1;
	margin-bottom: 5px
}
#content .content .aran{
	font-family: 'HelveticaCondensed', sans-serif;
	padding:30px 100px 30px 100px;
}

.content .aran h2,
#content .aper .general h2{
	background:#4470b5;
	color:#FFF;
	padding: 4px 0;
	margin-bottom:10px;
	font-family: 'HelveticaCondensed', sans-serif;
	font-size:20px;
	text-align:center;
}
.aper .general .formx{
	margin-top:20px;}

.general .formx label.ss.xss{
	margin-left: 0
}
.general .formx label.ss{
	min-width: 0;
	margin-left: 15px
}
.general .formx .check{
	overflow: hidden;
}
.general .formx label{
	display: inline-block;
	min-width: 150px
}
.general .formx input.flinputcheck2{
	margin-left: 20px
}
.general .formx .button-next:hover{
	color:#FFF;
	background: #002338
}
.general .formx .button-next{
	margin-top: 30px;
	margin-bottom: 30px;
	display: inline-block;
}
.general .formx input.flinputcheck{
	margin-left: 0
}
.general .formx input[name="CelPartTit"],
.general .formx input[name="TelPartTit"]{
	width:243px;
}
.general .formx input[name="email"]{
	width:232px;
}
.general .formx input[name="PaisPartTit"]{
	width:280px;
}
.general .formx input[name="ProvPartTit"]{
	width:280px;
}
.general .formx input[name="LocaCambioTit"]{
	width:270px;	
}
.general .formx input[name="LocaPartTit"]{
	width:330px;
}
.general .formx input[name="JubActIngre"]{
	width:410px
}
.general .formx input[name="JubCargo"],
.general .formx input[name="JubEmpresa"],
.general .formx input[name="DomCambioTit"]{
	width:500px;	
}
.general .formx input[name="RDDomLabTit"],
.general .formx input[name="AutDomLabTit"],
.general .formx input[name="DomPartTit"]{
	width:440px;
}
.general .formx input[name="CPCambioTitular"],
.general .formx input[name="CPPartTitular"]{
	width:164px;
}
.general .formx input[name="VigAutorHta"],
.general .formx input[name="VigAutorDde"],
.general .formx input[name="FecNacCon"],
.general .formx input[name="FecNacTit"]{
	width:120px;
}
.general .formx input.input-lt{
	width:200px;		
}
.general .formx input[name="NroCuit"]{
	width:150px;	
}
.general .formx input[name="AutorNDoc2"],
.general .formx input[name="AutorNDoc1"],
.general .formx input[name="NumDocCon"],
.general .formx input[name="NumDocTit"]{
	width:120px;
}
.general .formx input{
	width:540px;
	margin-left:6px;
	padding:4px 8px 4px 8px;
	background:#ebebeb;
	border:solid 1px #c0c0c0;
}
.general .formx select.tipo_doc{
	width:108px;
}
.general .formx select.sbigx{
	width:250px;
}
.general .formx select.sbig{
	width:300px;
}
.general .formx select{
	width:200px;
	margin-left:6px;
	padding:4px 8px 4px 8px;
	background:#ebebeb;
	border:solid 1px #c0c0c0;}

.general .formx input.domicilio{
	width:475px;
}
.general .formx p.shb{
	font-weight: bold;
	font-size:20px;
	margin-top: 20px;
	text-align: center;
	border:1px solid #002338;
	padding: 10px;
}
.general .formx p.sh{
	margin-top: 10px;
	line-height: 1.4
}
.general .formx .check p{
	position:relative;
	float:left;
	display:block;
}
.general .formx .check input{
	width:20px;
	height:20px;
	margin-top:10px;
	background:#ebebeb;
	border:solid 1px #c0c0c0;
	position:relative;
	float:left;
}
.general .formx .check span{
	position:relative;
	float:left;
	margin-left:5px;
}


.aper .general p a.button-prev{
	position:relative;
	position: absolute;
	left:0;
	top:30px;
	color:#002338;
	border:2px solid #002338;
	letter-spacing:1px;
	line-height: normal;
	padding:7px 20px 7px 20px;
}
.aper .general p a.button-next{
	position:relative;
	float:right;
	color:#002338;
	border:2px solid #002338;
	letter-spacing:1px;
	line-height: normal;
	padding:7px 20px 7px 20px;
}
.general .formx div{
	width:19%;
	position:relative;
	float:left;
}
.content .aran .izq{
	width:60%;
	position:relative;
	float:left;
}
.content .aran .der{
	position:relative;
	float: right;
	width:40%;
}

.content .aran ul li{
	border-bottom:solid 1px #c7c7c7;
	color:#002338;
	font-family: 'HelveticaCondensed', sans-serif;
	font-size:14px;
	padding:8px 0 8px 16px;
}
.content .aran ul li:last-child{
	border-bottom:none;
}

.content .aran .info{
	position:relative;
	float:left;
	margin-top:20px;}

.content .aran .info p{
	color:#9a999e;
	border:none;
	font-family: 'HelveticaMediumCondensed', sans-serif;
	font-size:12px;}

#content .content .aper{
	color:#4470b5;
	padding:30px 123px 10px 123px;
} 
#content .aper .perfi,#content .aper .perju{
	position:relative;
	float:left;
	width:30%;
	margin-top:30px;
}
#content .aper .perfi{
	margin-left:20%;
	background:#4470b5;
}
#content .aper .perju{
	margin-right:20%;
	background:#009de0;
}
#content .aper .per-in{
	color:#fff;
	padding:20px 20px 30px 20px;}

.aper .per-in h2{
	color:#FFF;
	font-size:26px;
	font-weight:normal;
	font-family: 'HelveticaMediumCondensed', sans-serif;}

#content .aper .general{
	margin:20px 0 20px 0;
	color:#002338;
	line-height:40px;
}

#content .aper .mensaje{
	background:#4470b5;
	padding:40px;
}

.aper .mensaje h2{
	color:#FFF;
	font-size:36px;
	font-weight:normal;
	font-family: 'HelveticaMediumCondensed', sans-serif;
}
.aper .mensaje p{
	color:#FFF;
	line-height:30px;}

.aper p.right{
	position: relative;
	text-align: right;
	padding-bottom: 30px
}

.cot-cont .cotizacion{
	height:100px;
	background: #FFF;
	padding:20px 0 20px 0;
}

#content .ap a:hover{
	color:#4470b3;
	background: #FFF;
}
#content .inv a:hover{
	color:#009cdf;
	background: #FFF;
}

#content .pbutton{
	margin-top:40px;
}
#content .ap a,#content .inv a{
	display: inline-block;
	position:relative;
	border:2px solid #fff;
	letter-spacing:1px;
	padding:7px 30px 7px 30px;
}
.cot-cont .cotizacion .indices .indice{
	margin-bottom: 20px
}
.cot-cont .cotizacion{
	position: relative;
}
.cot-cont .cotizacion .indices{
	height:93px;
	margin:0 40px;
	overflow: hidden;
}
.cot-cont .cotizacion .arrow.right{
	right: 10px
}
.cot-cont .cotizacion .arrow.left{
	left: 10px
}
.cot-cont .cotizacion > .arrow{
	top:50%;
	margin-top: -12px;
	position: absolute;
	cursor: pointer;
	z-index: 20
}
.cot-cont .cotizacion .indice{
	width:21%;
	position:relative;
	float:left;
	background:url(images/linea-vertical.png) right no-repeat;
}
.cot-cont .cotizacion .indice.end{
	background: none;
}
.cot-cont .indice .left{
	text-align:left;
	width:40%;
	margin-left:10%;
	float:left;
	padding-top:6px;
}
.cot-cont .indice span.ind{
  white-space: nowrap;
	background:#9a9a9a;
	letter-spacing:1px;
	color:#FFF;
	font-size:11px;
	padding:2px;
	text-transform:uppercase;
}
.cot-cont .indice span.valor{
  white-space: nowrap;
	margin-top:22px;
	display:block;
	font-size:12px;
}
.cot-cont .indice .right .arrow{
	display: block;
	text-align: right;
}
.cot-cont .indice .right{
	width:40%;
	float:right;
	margin-right:5%;
	text-align:left;
}
.cot-cont .cotizacion .indice:nth-child(6n+6){
	background: transparent;
}
.cot-cont .cotizacion .indices-in{
	position: relative;
}
.cot-cont .indice span.arrow img{
	margin-top:0;
}
.cot-cont .indice span.porcent{
	margin-top:7px;
	font-size:18px;
	display:block;
	margin-right: 2px;
	text-align: right;	
}
.cot-cont .readmore a:hover{
	background: #00aeef
}
.cot-cont .readmore a{
	padding:16px 0 16px 0;
	display: block;
	background:#9a9a9a;
}
.cot-cont .readmore{
	font-size:12px;
	color:#FFF;
	text-align:center;
	letter-spacing:2px;
}

/* --- //CONTENT --- */

#content .section-rodi .title {
  padding-top: 120px;
}
#content .section-rodi .title h3{
	margin-bottom: 20px
}

#content .content .aran.rodi{
padding: 0
}
#content .content .aran.rodi .iff .button:hover{
	opacity: .7
}
.iff-fixed #footer,
.iff-fixed #prefooter,
.iff-fixed #header{
	display: none;
}
.iff-fixed #content .content .aran.rodi .iff .button{
right: 30px
}
#content .content .aran.rodi .iff .button{
	position: absolute;
	top:10px;
	right: 10px;
	z-index: 502;
	cursor:pointer;
	opacity: 1;
	filter: alpha(opacity=1);
}
#content .content .aran.rodi .iff{
position: relative;
}
body.iff-fixed{
overflow: hidden;
}
.iff-fixed #content .content .aran.rodi .iff iframe{
	height: 100%
}
.iff-fixed #content .content .aran.rodi .iff{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 500;
}
#content .content .aran.rodi iframe{
	width:100%;
	border:0;
	height: 880px
}
/* --- PREFOOTER --- */
.section-contact #prefooter{
	display: none
}
#prefooter{
	background:#ebebeb;}
.logos{
	padding:0 10px;
	text-align: center;
}
.logos a.img{
	position:relative;
	padding:10px 0;
	display: inline-block;
}
.logos img{
	opacity:0.5;
}
.logos img:hover{
	opacity:1;}
.contact{
	color:#FFF;
	font-family: 'HelveticaMediumCondensed', sans-serif;
	letter-spacing: .5px;
	text-align:center;
	height:134px;
	padding-top:55px;
	background:url(images/contact.png) center no-repeat;}
.contact a:hover{
	background: #FFF;
	color:#00aeef;
}
.more-info{
	padding-top:10px;
	padding-bottom:40px;
	text-align: center;
}
.more-info a:hover{
	color:#FFF;
	background: #4470b5;
	top:-3px;
}
.more-info a{
	position: relative;
	top:0;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  font-family: 'HelveticaMediumCondensed', sans-serif;
  border: 2px solid #4470b5;
  padding: 10px;
  color:#4470b5;
  font-size:14px;
}
.contact a{
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: 'HelveticaMediumCondensed', sans-serif;
	border:2px solid #fff;
	padding:10px;
}
.contact .link{
	margin-top:35px;
}

/* --- PREFOOTER --- */


/* --- FOOTER --- */
#footer {
	background: #1e293c;
	color:#FFF;
	font-size:14px;
	height:auto;
}
#footer a:hover{
	color:#9a9a9a;
}
#footer a{
	color:#FFF;
	line-height:20px;
}
#footer h2{
	color:#9a9a9a;
	font-size:20px;
	line-height:30px;
}
#footer div.footer-in {
	position:relative;
	padding:50px 0 30px 0;
}

.footer-in .left,.footer-in .center,.footer-in .right{
	position:relative;
	float:left;
}
.footer-in .left{
	width:23%;
	margin-left:12%;
}

.footer-in .center{
	width:35%;
}
.footer-in .right{
	width:23%;
}
.footer-in .redes div{
	cursor:pointer;
	width:37px;
	height:37px;
	position:relative;
	float:left;
	margin:30px 27px 36px 0;}

.footer-in .redes div.end{
	margin-right:0;}

.footer-in .redes .fb{
	background:url(images/social_fb.png) left no-repeat;}
.footer-in .redes .fb:hover{
	background:url(images/social_fb_hover.png) left no-repeat;}
.footer-in .redes .tw{
	background:url(images/social_tw.png) center no-repeat;}
.footer-in .redes .tw:hover{
	background:url(images/social_tw_hover.png) center no-repeat;}
.footer-in .redes .gplus{
	background:url(images/social_gplus.png) center no-repeat;}
.footer-in .redes .gplus:hover{
	background:url(images/social_gplus_hover.png) center no-repeat;}
.footer-in .redes .in{
	background:url(images/social_in.png) right no-repeat;}
.footer-in .redes .in:hover{
	background:url(images/social_in_hover.png) right no-repeat;}

.footer-in .responsable{
	width:70%;
	float:left;
	background:#30C}
.footer-in .fiscal{
	width:30%;
	float:left;
	background:#0C0}
	
#footer div.sub-footer {
	border-top:solid #848a93 2px;
	text-align:center;
	font-size:15px;
	line-height: 18px;
	padding-top:15px;
	padding-bottom:15px;
}
/* --- //FOOTER --- */
#content .content .aran.widget-cotizaciones h4 .app-span-bold{
	display: inline-block
}
#content .content .aran.widget-cotizaciones{
	padding: 40px
}
.widget-cotizaciones ul.title-tab {
  padding: 0;
  display: block;
  word-wrap: nowrap;
  padding-bottom: 1px;
  height: 28px;
  overflow: hidden;
}
.widget-cotizaciones ul.title-tab li:last-child:hover,
.widget-cotizaciones ul.title-tab li:hover{
  background: #2A6FAD;
  color: #FFF;
}

.widget-cotizaciones ul.title-tab li:last-child.active,
.widget-cotizaciones ul.title-tab li.active{	
  background: #15314A;
  color: #FFF;
}
.widget-cotizaciones .filters select{
	width:250px;
	padding: 5px;
	border:1px solid #c7c7c7;
	font-size:14px;
	border-radius: 3px;
	margin-right: 5px;
}
.widget-cotizaciones .filters input{
	width:300px;
	padding: 5px;
	border-radius: 3px;
	border:1px solid #c7c7c7;
	font-size:14px;
}
.widget-cotizaciones .filters{
	margin-top: 20px;
}
.widget-cotizaciones ul.title-tab li a{	
	transition: all .2s;
	display: block
}
.widget-cotizaciones ul.table-tab table td{
	border-bottom:1px solid #DDD;
	padding: 6px 0;
	text-align: center
}
.widget-cotizaciones ul.table-tab table td a.link:hover{
	text-decoration: none
}
.widget-cotizaciones ul.table-tab table td a.link{
	text-decoration: underline;
}
.widget-cotizaciones ul.table-tab table td:first-child{
	font-weight: bold;
	text-align: left
}
.widget-cotizaciones ul.table-tab table th:first-child{
	text-align: left
}
.widget-cotizaciones ul.table-tab table th{
	border-bottom:1px solid #002338;
	padding-bottom: 5px;
	font-weight: bold;
	font-size: 16px;
	text-align: center
}
.widget-cotizaciones ul.table-tab table{
	width:100%;
	min-width: 800px;
}
.widget-cotizaciones ul.table-tab li.active{	
	z-index: 10;
	opacity: 1
}
.widget-cotizaciones ul.table-tab{
	margin-top: 15px;
	position: relative;
	overflow: hidden;
}
.widget-cotizaciones ul.table-tab li{	
	position: absolute;
	top:0;
	left:0;
	right: 0;
	z-index: 1;
	border-bottom:0;
	padding: 0;
	opacity: 0;
	transition: opacity .6s;
	background: #FFF;
	overflow-x:auto;
}
.widget-cotizaciones.wservicios ul.table-tab li .text{
	color:#000;
}
.widget-cotizaciones.wservicios ul.title-tab{
	margin-bottom: 30px;
	height: auto;
}
.widget-cotizaciones.wservicios ul.title-tab li:last-child,	
.widget-cotizaciones.wservicios ul.title-tab li{		
	font-size:16px;
	line-height: 22px;
	height: 22px;
}
.widget-cotizaciones ul.title-tab li:last-child,
.widget-cotizaciones ul.title-tab li{		
	transition: all .2s;
  border-bottom: solid 1px #c7c7c7;
  height: 18px;
  line-height: 18px;
  list-style: none;
  background-color: #eee;
  float: left;
  padding: 5px 8px;
  margin-right: 3px;
}

#content .aper .button-sel:hover{
	opacity: .8
}
#content .aper .button-sel{
	opacity:1;
	transition: opacity .2s;
	cursor:pointer;
}
div#content .section-account .box-account{
	padding-bottom: 45px;
}

@media screen and (max-width: 1100px) {

.cot-cont .cotizacion .indice:nth-child(6n+4){
	background:transparent;
}
.cot-cont .cotizacion .indice:nth-child(6n+5){
	display: none
}
.cot-cont .cotizacion .indice:nth-child(6n+6){
	display: none
}
	#content .title{
    padding-top: 96px;
	}
	.header-fixed #header .app-inside .logo{
		top:5px;
	}
	#header .app-inside{
    height: 80px;
	}
	body{
		background-size: 400px
	}
	.header-fixed #header .top{
		height: 40px
	}
	.footer-in .redes div{
		margin:5px 4px 10px;
	}
	.footer-in .right {
    width: 26%;
	}
	#content .content .ap, #content .content .inv{
		width:50%;
    height: 225px;
    box-sizing: border-box;
	}
	.content .form-in{
		padding: 0;
	}
	#content .title h2{
		padding-bottom: 17px;
	}
	#content .content .form{
		width:320px;
		float:none;
		margin:0 auto;
	}
	#content .title h3{
    max-width: 460px;
		font-size:50px;
		padding-bottom: 24px
	}
	#content .box{
		overflow: hidden;
	}
	#content .content .map{
		width:600px;
		max-width: 90%;
		float:none;
		padding-top: 0;
		margin:0 auto;
	}
	.widget-cotizaciones.wservicios ul.title-tab li:last-child, .widget-cotizaciones.wservicios ul.title-tab li{
		margin-bottom: 5px;
	}
	.widget-cotizaciones ul.title-tab li:last-child, .widget-cotizaciones ul.title-tab li{
		margin-bottom: 5px;
	}
	.widget-cotizaciones .filters input {
    width: 180px;
    font-size:13px;
  }
  .widget-cotizaciones ul.title-tab{
  	height: auto
  }
	.widget-cotizaciones .filters select {
    width: 200px;
    font-size:13px;
  }
  #content .ap h2, #content .inv h2{
  	margin-bottom: 15px
  }
  #content .pbutton {
    margin-top: 15px;
    margin-bottom: 15px;
	}
	#content .content .fb{	
    display:none;
	}

.cot-cont .cotizacion .indices h1{
	margin-left: 12px
}
	#content .content .aran.widget-cotizaciones,
	#content .content .aran {
    padding: 30px;
	}
	#content .title .fbutton {
    top: 150px;
    right: 0px;
	}
	.header-fixed #header div.app-inside .menu {
    padding-top: 7px;
	}
	#header div.app-inside .menu{
		width:400px;
		text-align: center;
		float:right;
	}
	#prefooter .app-inside{
		width:auto;
		margin-left:0;
		margin-right:0;
		max-width: none;
		padding:0
	}
		.section-online-holding{
			.app-inside{
				width:auto;
				@media screen and (min-width: 450px) and (max-width: 520px){
					padding:0 15px;
				}		
				@media screen and (min-width: 521px) {
					padding:0 25px;
					margin: auto !important;
				}		
		}
	}
	.app-inside{
		width:680px;
	}
}
@media screen and (max-width: 730px) {
	.widget-cotizaciones .filters input{
    width: 200px;
	}
	.section-online-holding__current-accounts > .d-flex{
    padding-right: 0;
    padding-left: 0;
	}
	.section-online-holding__current-accounts{
		display: block;
		margin-left:0;
		margin-right:0;
	}

	#content .content .aran {
    padding: 30px;
	}
	.content .aran ul li{
		.der{
			float:none;
			width:100%			
		}
		.izq{
			float:none;
			font-weight: bold;
			margin-bottom:5px;
			width:100%
		}

	}
	#content .title h2{
		margin-bottom:15px
	}
	#footer div.footer-in{
		padding-top:30px
	}
	.menu ul li.m-apertura{
		display: none
	}
	body{
		background-size: 350px
	}
.cot-cont .cotizacion .indice{
	width:35%;
}
.cot-cont .cotizacion .indices{
	margin:0 10px;
}/*
#content .cot-cont{
	margin-top:-35px;
}*/
.cot-cont .cotizacion{
	margin-top: 0
}/*
.widget-cotizaciones ul.table-tab li{
	overflow: scroll
}*/
.widget-cotizaciones .filters select{
	margin-bottom: 6px;
}
#header .app-inside .logo img{
	position: static
}
.header-fixed #header .app-inside .logo img.logo-basic{
	display: none
}
#header .app-inside .logo img.logo-x{
	display: none
}
.footer-in .left{
    margin-left: 6%;

}
#header .app-inside .logo{
	float:none;
	width:auto
}
#header div.app-inside .menu {
    width: auto;
    text-align: center;
    float: none;
    clear: both;
}
/*
.header-fixed #header{
	display: none
}
#header .app-inside .logo img{
	height: 30px
}
*/
.footer-in .right .redes{
	overflow: hidden;
}
.footer-in .left,
.footer-in .center{
	width:43%;
}
@media only screen and (max-width: 550px) {
	#header .app-inside .logo img{
		height: 40px
	}

	body{
		background-size: 300px
	}
.footer-in .left{
  margin-left: 6%;
	width:auto;
	float:none;
}
.footer-in .center{
	float:none;
	width:auto;
	margin-top:20px;
	margin-bottom:20px;
  margin-left: 6%;
}
}
.sub-footer .bb{
	display: none

}
.footer-in .right{
    clear: both;
    margin-left: 6%;
	float:none;
	width:auto;
}
#content .title h3{
	max-width: none;
}

	.app-inside{
		max-width:none;
		width:auto;
		margin-left:10px;
		margin-right:10px;
	}
		#content .ap h2, #content .inv h2{
		position: absolute;
	}
	#content .ap .text, #content .inv .text{
		padding-top: 40px
	}
	.cc-quienes-somos .xbox{
		width:auto;
		float:none;
	}

}
@media screen and (max-width:650px) {

.sub-footer .app-inside{
    max-width: 250px;
    margin:auto
}
}
@media screen and (max-width: 420px) {
	#content .title h2{
		margin-bottom:10px;
	}
	#content .form-in .boton,
	#content .form-in input, 
	#content .form-in textarea{
    box-sizing: border-box;
		width:100%
	}
	#content .map .pre{
		margin-top: 10px;
		padding:0
	}
	#content .content .map,
	#content .content .form{
		width:auto;
		padding:15px;
	}
	.cot-cont .cotizacion .indice:nth-child(6n+4){
		display: none
	}
.cot-cont .cotizacion .indice:nth-child(6n+3){
	background:transparent;
}
	#content .cot-cont,
	#content .content{
		margin-left: -12px;
		margin-right: -12px;
	}
	#content .content .aran {
    padding: 15px;
	}
	#header .app-inside{
		padding:0
	}
}

@media only screen and (max-width: 350px) {
	body .rmenu-btn{
		top:15px
	}
	#header .app-inside .logo img{
		height: 35px
	}
}
// @media screen and (max-width: 320px) {
// 	.app-inside {
//     max-width: 280px;
// 	}
// }


.btn {
	text-transform: uppercase;
}


.bootstrap-select.btn-group .dropdown-toggle .filter-option {
	margin-top: 2px;
}

.flex-c {
  flex-direction: column;
  display: flex;
}
.flex-r {
  flex-direction: row;
  display: flex;
}
.flex-c-sm {
	@media only screen and (max-width: 1024px) {
		flex-direction: column!important;
	}
}
.full-width {
  width: 100%;
}
.text-align-center {
  text-align: center;
}
.full-height {
  height: 100%;
}
.flex-wrap {
  flex-wrap: wrap;
}

.vertical-center {
	display: flex;
	justify-content: center;
	align-items: center;
}


.widget-simple {
	padding: 15px 0;
	background: $brand-secondary;
	text-align: center;
	color: white;
	@media only screen and (max-width: 1024px) {
		&.widget-info-rel{
			margin: 0			
		}
		margin: 0 -10px;
	}
	.fa {
		position: relative;
		margin-left: 2px;
		bottom: -2px;
		font-size: 20px;
		color: $brand-primary;
		transition: margin .3s;
	}
	.relevant{
		text-transform: uppercase;
		float: left;
		padding-left: 20px;
		padding-right: 15px;
		@media only screen and (max-width: 1024px) {
			float: none;
			padding: 5px 10px;
			text-align: left;
			padding-left: 30px;
		}
	}
	.articles {
		float: left;
		overflow: hidden;
		height: 14px;
		@media only screen and (max-width: 1024px) {
			float: none;
			padding: 10px 30px;
			//height: 60px;
			text-align: left;
		}
		.article {
			font-weight: 100;
			color: gray;
			position: absolute;
			background: $brand-secondary;
			opacity: 0;
			transition: opacity .6s;
			@media only screen and (min-width: 1024px) {
				text-overflow:ellipsis;
				white-space:nowrap; 
				overflow:hidden; 
			}

			max-width: 70%;
			&.active {
				opacity: 1;
			}
		}
	}
}

.widget-simple:hover .fa {
	margin-left: 5px;
}

.list-articles-container {
	padding: 50px 50px 30px 50px;
	margin: 0;
	@media only screen and (max-width: 1024px) {
		padding: 30px 15px;
	}
}
.list-articles {
	position: relative;
	.article {
		padding: 10px;
		width: 50%;
		box-sizing: border-box;
		@media only screen and (max-width: 1024px) {
			width: 100%;
		}
		&.big {
			width: 100%;
			.box {
				font-size: 14px;
				.date {
					color: black;
				}
			}
		}
		.box {
			overflow: visible !important;
			border: 1px solid darken(#ebebeb,10%);
			color: $brand-primary;
			padding: 15px;
			line-height: 1.3;
			box-sizing: border-box;
			position: relative;
			font-size: 11px;
			.date {
				display: inline-block;
			    background: white;
			    padding: 5px 10px;
			    position: absolute;
			    top: -10px;
			    margin-left: -10px;
			    color: gray;
			    font-size: 11px;
			    font-weight: 500;
			    font-family: 'HelveticaMediumCondensed', sans-serif;
			    z-index: 1000;
			}
		}

	}
}

.pagination {
	text-align: right;
	margin-right: 60px;
	padding-bottom: 40px;
	a {
		background: #ebebeb;
		padding: 5px 8px;
		color: $brand-primary;
		display: inline-block;
		font-size: 16px;
		&:hover {
			background: $brand-primary;
			color: white;
		}
	}
}

#content .title .input select{
	border-radius: 4px;
}
.drop-down-list .btn,
.drop-down-list .filter-option{
	font-family: inherit
}
.drop-down-list.show .dropdown-toggle{
	border-radius:4px 4px 0 0;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}
.drop-down-list .dropdown-toggle{
	text-align: center;
	border-radius:4px;
	background: #FFF;
	transition: none;
	padding-right: 32px;
	font-weight: 500;
	color: #333;
}
.drop-down-list .dropdown-menu.open{
	border-radius: 0 0 4px 4px;
	border: 0;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

.drop-down-list.select-change-date.show  .dropdown-toggle{
	border-bottom:1px solid #DDD;
}
#content .title select.drop-down-list{
	border-radius:4px;
	height: 40px;
	padding-right: 32px;
	padding: 10px;
}
.drop-down-list.select-change-date .filter-option{
	text-align: center;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.6px;
	font-weight: 500;
}
.drop-down-list.select-change-date .span-ten-update{
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.6px;
	font-weight: 500;
	text-align: center;
  br{
  	display: none
  }
}
.drop-down-list.select-change-date .opt-ten-update{
	font-size:16px;
	line-height: 18px;
	font-weight: normal;
	text-align: center
}
.drop-down-list.select-change-date .dropdown-toggle::after{
	position: absolute;
	top: 50%;
	margin-top: -2px
}
.drop-down-list.select-change-date a{
	font-size: 15px;
	text-align: center
}
.drop-down-list.select-change-date  .dropdown-toggle{
	padding-top:5px;
	padding-bottom:5px;
	height: 40px;
}
#content .title select.select-change-date{
	height: 40px;
	font-size: 15px;
	text-align: center;
	padding-right: 32px;
	color: transparent;
}
#content .title .select-change-date,
.drop-down-list.select-change-date{
	margin: 0 5px;
	max-width: 100%;
	display: inline-block;	
	.dropdown-toggle{
		padding-top: 3px;
		padding-left: 8px;
	}
	.filter-option{
		text-align: center !important;
	}
}
.drop-down-list{
	display: block;
	max-width: 500px;
	width:100%;
	margin-top: 5px;
	margin-bottom: -20px;
	text-transform: uppercase;
	font-family: "Helvetica Condensed", sans-serif;
}
#app-body .drop-down-list li.disabled a{
	background: #EEE;
	color: #888;
}
#app-body .drop-down-list li:not(.disabled) a:hover{
	color: #FFF;
	background: #616e82;
}
#app-body .drop-down-list li:not(.disabled).selected a,
#app-body .drop-down-list li:not(.disabled).selected a:hover{
	color: #FFF;
	background: #4470b5;
}

#app-body .drop-down-list.client-list .dropdown-menu{
	background-color: transparent
}
#app-body .drop-down-list.client-list li:last-child{
	border-radius: 0 0 4px 4px;
	overflow: hidden
}
#app-body .drop-down-list.client-list li a{
	font-size:16px;
	line-height: 21px;
}
#app-body .drop-down-list{
margin-top: 0
}
#app-body .drop-down-list li a{
	background: #FFF;
	transition: background .1s;
	border-top: 1px solid #DDD;
	color: #333;
	font-weight: 500;
	font-size: 18px;
	padding: 10px 15px 8px;
}
@media screen and (max-width: 730px) {
	#content .section-home .widget-simple{
		margin-left: 0;
		margin-right: 0;
	}
	#content .content .ap, 
	#content .content .inv {
		p{
			font-family: 'HelveticaCondensed', sans-serif
		}
	}
	#content .title .fbutton {
	  top: 100px;
	  right: 50px;
	}
	#content .section-home .title h3{
		font-size:30px;
		padding-right: 250px;
	}
	#content .section-home .title h2{
		display: none
	}
}

@media screen and (max-width: 550px) {
	#content .title .fbutton a{

		color: #FFF;
		background: rgba(0,0,0,.3);
		border: 2px solid #FFF;
		width: auto;
		padding: 10px 30px;
		font-size: 22px;
		display: block;
		text-transform: uppercase;
	}
	#content .title .fbutton {
		position: static;
		text-align: center;
		margin-bottom: 0

	}
	#content .section-home .title h3{
		font-size:30px;
		text-align: center;
		padding: 0 10px;
		max-width: 250px;
		margin:auto;
		margin-top: -15px;
		margin-bottom: 15px;
	}
}
@media screen and (max-width: 520px) {
	#content .content .ap, #content .content .inv {
		height: 245px;
	}
}
@media screen and (max-width: 500px) {
	#content .section-home .content{
		margin:0
	}
	#content .content .ap, #content .content .inv {
	    width:auto;
	    float:none;
	    height: auto;
	    box-sizing: border-box;
	}
}
