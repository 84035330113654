@media only screen and (max-width:1100px) {
	.fancybox-arrow--right{
		right: 0
	}
	.fancybox-arrow{
		width:44px
	}
}
@media only screen and (max-width:900px) {
	.fancybox-navigation{
		display: none
	}
}
@media only screen and (min-width:901px) {
	.section-document {
		.button-ticket{
			display: none
		}
	}
}
.fancybox-stage{
	.section-document {
		background: transparent;
		max-width:1020px;
		overflow-x: hidden;
		width:100%;
		@media only screen and (max-width:1100px) {
			.ticket-transaction-information table{
				display: table;
			}
			padding: 0 10px;
			margin: 10px 0;
    	overflow: visible;
			.app-inside{
    		margin: 10px auto;
				width: 100%;
				max-width: 850px
			}
			.ticket__body{
				padding: 20px;
			}
		}
		@media only screen and (max-width:900px) {
			.print-ticket{
				display: none
			}
			.app-inside{
    		margin: 0;
				width: 100%;
				max-width: none
			}
			.fancybox-close-small {
		    right: 14px;
		  }
			.ticket-header {
		    padding: 10px 20px;
				@media only screen and (max-width:420px) {
		    	padding:	10px
				}
		  }
			.table thead th {
		    vertical-align: middle;
  		  font-weight: normal;
    	}		
    	.ticket-sign{
    		margin:auto;
    		min-width: 300px;
    		float: none;
    		margin-bottom: 60px;
    	}
    	.ticket-ll{
    		float:none;
    		text-align: center;
    		margin: auto;
    		width:100%;
		    padding-top: 20px;
		    padding-bottom: 150px;
				@media only screen and (max-width:600px) {
		    	padding-bottom:	80px
				}
    	}
    	.ticket-transaction-information{
    		overflow-x:auto
    	}
  		.ticket-transaction-information table{
  			min-width: 800px;
				display: block;
				@media only screen and (max-width:600px) {
		    	min-width:	760px;
		    	width:auto;
				}
			}
    }
		@media only screen and (max-width:750px) {
			.ticket-header__data {
		    font-size: 12px;
		    text-align: left;
		    column-gap:12px;
		    column-count: 2;
			}
			.ticket-header img {
		    max-height: 45px;
		  }
			.ticket-header__text {
			    padding-left: 20px;
			    padding-right: 30px;
			}
    }
		@media only screen and (max-width:650px) {
			.ticket-header img {
				max-height: 60px;
				margin:auto
			}
			.ticket-header{
				display: block;
			}
			.ticket-header__text {
				padding: 20px 0 7px;
		    text-align: left;
		    margin: auto;
		    width: auto;
			}
    }
		@media only screen and (max-width:420px) {
			.ticket-ll{
				padding-top: 5px;
			}
			.ticket-type__additional-information{
				margin-top: 0;
			}
			.ticket-type__label{
				font-size: 20px
			}
			.ticket-header__data {
				font-size: 11px;
	    	column-gap: 8px;
	    	padding-top: 0
		  }
			.ticket-header{
				text-align: left
			}
			div.ticket-header img {
				max-height: 50px;
				margin-left:0;
			}
		}

	}
	.section-document__introduction{
		background: transparent;
		>.app-inside{

			>.title{
				display: none;
			}
		}
	}
}
.section-document {
  background: none;
  background-color: #ebebeb;
  color: #333333;
  #content {
    min-height: 0;
  }/*
  #prefooter {
    margin-top: $spacer*28.5;
    @media only screen and (max-width: 1024px) {
      margin-top: $spacer*35.5;
    }
    @media only screen and (max-width: 568px) {
      margin-top: 0;
    }
    .logos {
      display: none;
    }
  }*/
  #header .menu ul li{
    &.m-usuario {
      display: inline-block!important;
      background: none;
      a {
        color: $contact-icon;
        font-size: $font-size-base*1.125;
      }
    }
  }
  &__introduction {
    background:url(images/background.jpg) transparent top center no-repeat fixed;
    background-size:cover;
    @media only screen and (max-width: 568px) {
      height: auto;
      .title {
        padding-bottom: $spacer*8;
      }
    }
  }
  &__table {
    @extend .row, .flex-r;
    margin-top: -$spacer*4.8;
  }
}

$table-pink : #ff9999;
$table-blue : $blue;
$table-green : #659a3a;
$table-yellow : #cc2f00;
$table-yellow2: #525252;

.ww-side .information-table table{
	margin-top: 0
}
.ww-side .information-table{
	width: 65%;
	float: right;
	margin-right: 2%;
}
.ww-side .side{
	float: left;
	width: 30%;
	margin-right: 3%;
}
.sxll{
	display: inline-block;
	width: 45%;
}

.ticket {
  @extend .flex-c, .full-width;
  background-color: $white;
  font-size: $font-size-base*.9375;
  font-family: $font-family-condensed-final;
  &__body {
    padding: $spacer*1.6 $spacer*2.4 $spacer*3.2 $spacer*3.2;
    @media only screen and (max-width: 568px) {
      padding: $spacer*1.6;
    }
  }
  @media only screen and (max-width: 568px) {
    margin-bottom: 0;
  }
  &_sale {
		.ticket-sign {
	  	border-top:1px solid $table-pink !important;
		}
	  .trfoot td{
	    padding: 0.75rem !important;
	    font-family: "Helvetica Condensed", sans-serif;
	    font-weight: 700;
	    font-size: 0.9375rem;
	  	border-bottom:1px solid $table-pink !important;
	  	border-top:1px solid $table-pink !important;
	  	border-right:0 !important;
	  	border-left:0 !important;
	  }
    .ticket-type {
      border-bottom: $spacer*.1 solid $table-pink;
      &__label {
        background-color: $table-pink;
      }
    }
    .ticket-transaction-information {
      &__label {
        color: $table-pink;
      }
      &__observations {
        color: $table-pink;
      }
      table {
        thead {
          tr {
            &:first-of-type {
              background-color: $table-pink;
            }
            &:last-of-type {
              background-color: rgba($table-pink,0.3);
              color: $table-pink;
              th {
                &:first-of-type {
                  border-left: $spacer*.1 solid $table-pink;
                }
                border-right: $spacer*.1 solid $table-pink;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              border-right: $spacer*.1 solid $table-pink;
              &:first-of-type {
                border-left: $spacer*.1 solid $table-pink;
              }
            }
            &:last-of-type {
              td {
                border-bottom: $spacer*.1 solid $table-pink;
              }
            }
          }
        }
      }
    }
  }
  &_buy {
	  .trfoot td{
	    padding: 0.75rem !important;
	    font-family: "Helvetica Condensed", sans-serif;
	    font-weight: 700;
	    font-size: 0.9375rem;
	  	border-bottom:1px solid $table-green !important;
	  	border-top:1px solid $table-green !important;
	  	border-right:0 !important;
	  	border-left:0 !important;
	  }
    .ticket-type {
      border-bottom: $spacer*.1 solid $table-green;
      &__label {
        background-color: $table-green;
      }
    }
    .ticket-sign{
      border-color:$table-green;
    }
    .ticket-transaction-information {
      &__label {
        color: $table-green;
      }
      &__observations {
        color: $table-green;
      }
      table {
        thead {
          tr {
            &:first-of-type {
              background-color: $table-green;
            }
            &:last-of-type {
              background-color: rgba($table-green,0.3);
              color: $table-green;
              th {
                &:first-of-type {
                  border-left: $spacer*.1 solid $table-green;
                }
                border-right: $spacer*.1 solid $table-green;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              border-right: $spacer*.1 solid $table-green;
              &:first-of-type {
                border-left: $spacer*.1 solid $table-green;
              }
            }
            &:last-of-type {
              td {
                border-bottom: $spacer*.1 solid $table-green;
              }
            }
          }
        }
      }
    }
  }
  &_rest {
  	&.neg{

  		.ww-side .information-table table th{
  			border-top: 1px solid $table-yellow2;
  		}

			.ticket-sign {
				border-top:1px solid $table-yellow2 !important;
			}  		
		  .trfoot td{
	  		border-bottom:1px solid $table-yellow2 !important;
	  		border-top:1px solid $table-yellow2 !important;
		  }

		  .ticket-type {
		    border-bottom: $spacer*.1 solid $table-yellow2;
		    &__label {
		      background-color: $table-yellow2;
		    }
		  }
		  .ticket-transaction-information {
		  	&__label {
		  		color: $table-yellow2;
		  	}
		  	&__observations {
		  		color: $table-yellow2;
		  	}
		  	table {
		  		thead {
		  			tr {
		  				&:first-of-type {
		  					background-color: $table-yellow2;
		  				}
		  				&:last-of-type {
		  					background-color: rgba($table-yellow2,0.3);
		  					color: $table-yellow2;
		  					th {
		  						&:first-of-type {
		  							border-left: $spacer*.1 solid $table-yellow2;
		  						}
		  						border-right: $spacer*.1 solid $table-yellow2;
		  					}
		  				}
		  			}
		  		}
		  		tbody {
		  			tr {
		  				td {
		  					border-right: $spacer*.1 solid $table-yellow2;
		  					&:first-of-type {
		  						border-left: $spacer*.1 solid $table-yellow2;
		  					}
		  				}
		  				&:last-of-type {
		  					td {
		  						border-bottom: $spacer*.1 solid $table-yellow2;
		  					}
		  				}
		  			}
		  		}
		  	}

		  }
	  }


	  .ww-side .information-table table th{
	  	border-top: 1px solid $table-yellow;
	  }

		.ticket-sign {
	  	border-top:1px solid $table-yellow !important;
		}
	  .trfoot td{
	    padding: 0.75rem !important;
	    font-family: "Helvetica Condensed", sans-serif;
	    font-weight: 700;
	    font-size: 0.9375rem;
	  	border-bottom:1px solid $table-yellow !important;
	  	border-top:1px solid $table-yellow !important;
	  	border-right:0 !important;
	  	border-left:0 !important;
	  }
    .ticket-type {
      border-bottom: $spacer*.1 solid $table-yellow;
      &__label {
        background-color: $table-yellow;
      }
    }
    .ticket-transaction-information {
      &__label {
        color: $table-yellow;
      }
      &__observations {
        color: $table-yellow;
      }
      table {
        thead {
          tr {
            &:first-of-type {
              background-color: $table-yellow;
            }
            &:last-of-type {
              background-color: rgba($table-yellow,0.3);
              color: $table-yellow;
              th {
                &:first-of-type {
                  border-left: $spacer*.1 solid $table-yellow;
                }
                border-right: $spacer*.1 solid $table-yellow;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              border-right: $spacer*.1 solid $table-yellow;
              &:first-of-type {
                border-left: $spacer*.1 solid $table-yellow;
              }
            }
            &:last-of-type {
              td {
                border-bottom: $spacer*.1 solid $table-yellow;
              }
            }
          }
        }
      }
    }
  }
}

.ticket-header {
  @extend .flex-r;
  padding: $spacer*3.2 $spacer*2.4 $spacer*2.4 $spacer*3.2;
  background-color: rgba(#999999,0.3);
  @media only screen and (max-width: 568px) {
    flex-direction: column!important;
  }
  img {
    @extend .flex-r;
    flex-shrink: 0;
    width: auto;
    height: auto;
    max-height: 125px;
    margin:auto 0;
    position: relative;
    top:-15px;
    @media only screen and (max-width: 1100px) {
    	max-height: 60px;
    	top:0
    }
  }
  &__text {
    @extend .full-width, .flex-c;
    text-align: right;
    @media only screen and (max-width: 1100px) {
    	padding-left: 40px;
    	padding-right: 40px;
    }
    @media only screen and (max-width: 568px) {
      text-align: center;
    }
  }
  &__title {
    font-family: $font-family-condensed-final;
    font-weight: 500;
    font-size: $font-size-base*1.5625;
    letter-spacing: 0.026em;
    margin-bottom: $spacer*.8;
    @media only screen and (max-width: 1100px) {
    	display: none
    }
  }
  &__data {
    font-family: $font-family-condensed-final;
    font-weight: 300;
    letter-spacing: 0.026em;
    font-size: $font-size-base*.9375;
    line-height: 1.4;
    @media only screen and (max-width: 1100px) {
    	font-size: $font-size-base*.8;
    	text-align: left;
    	column-gap:35px;
    	column-count: 2;
    }
  }
}

.ticket-type {
  @extend .flex-r;
  border-bottom: $spacer*.1 solid $blue;
  font-size: $font-size-base*.9375;
  font-family: $font-family-condensed-final;
  margin-bottom: $spacer*1.6;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.026em;
  @media only screen and (max-width: 568px) {
    flex-direction: column!important;
    height: auto;
  }
  &__person-information {
    text-align: left;
    padding-bottom:15px;

  }
  &__additional-information {
    text-align: right;
    margin-left: auto;
    padding-bottom:15px;
    @media only screen and (max-width: 568px) {
      text-align: left;
      margin-left: 0;
      margin-top: $spacer;
    }
  }
  &__label {
    @extend .text-uppercase;
    font-family: $font-family-condensed-final;
    font-weight: 800;
    font-size: $font-size-base*1.708125;
    color: $white;
    min-width: $spacer*21.6;
    text-align: center;
    background-color: $blue;
    padding: $spacer*.6 $spacer*1.3 $spacer*.6;
    margin-bottom: $spacer*1.6;
  }
}

.ticket-transaction-information {
  line-height: 1.4;
  letter-spacing: 0.026em;
  &__label {
    @extend .text-uppercase;
    color: $blue;
    margin-left: $spacer*1.2;
    font-weight: 700;
  }
  &__detail {
    font-weight: 300;
  }
  &__observations {
    color: $blue;
    font-weight: 700;
  }
  table {
    display: table;
    margin-top: $spacer*1.6;
    border-collapse: collapse;
    text-align: left;
    font-size: $font-size-base;
    font-weight: 500;
    font-family: $font-family-condensed-final;
    letter-spacing: 0.026em;
    thead {
    	text-align: center;
      tr {
        &:first-of-type {
          background-color: $blue;
          color: $white;
          th {
            border-right: $spacer*.1 solid $white;
          }
        }
        &:last-of-type {
          background-color: rgba($blue,0.3);
          color: $blue;
          th {
            &:first-of-type {
              border-left: $spacer*.1 solid $blue;
            }
            padding-top: $spacer*.6;
            padding-bottom: $spacer*.6;
            border-bottom: none;
            border-right: $spacer*.1 solid $blue;
          	font-size:14px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-family: $font-family-condensed-final;
          font-weight: 300;
          font-size: $font-size-base*.9375;
          padding-top: $spacer*.2;
          padding-bottom: $spacer*.2;
          border-top: none;
          border-right: $spacer*.1 solid $blue;
          &:first-of-type {
            border-left: $spacer*.1 solid $blue;
          }
          &:last-of-type {
            text-align: right;
          }
        }
        &:first-of-type {
          td {
            padding-top: $spacer*.4;
          }
        }
        &:last-of-type {
          td {
            padding-bottom: $spacer*.4;
            border-bottom: $spacer*.1 solid $blue;
          }
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      display: block;
    }
    tfoot {
      tr {
        td {
          font-family: $font-family-condensed-final;
          font-weight: 700;
          font-size: $font-size-base*.9375;
          &:last-of-type {
            text-align: right;
          }
        }
      }
    }
    &.type-2-table {
      margin-bottom: $spacer*2.4;
      thead {
        tr {
          th {
            &:last-of-type {
              text-align: left;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            padding-top: $spacer*1.2;
            padding-bottom: $spacer*1.2;
            &:last-of-type {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.ticket-ll {
  float:left;
  width:50%;
  padding-top:123px
}
.ticket-sign {
  float:left;
  width:50%;
  line-height: 1.4;
  flex-direction: column;
  border-top: $spacer*.1 solid $blue;
  margin-top: $spacer*8.5;
  padding-top: $spacer*1.2;
  margin-left: auto;
  text-align: center;
  font-weight: 300;
  margin-bottom: $spacer*5;
  letter-spacing: 0.026em;
  &__note {
    font-weight: 700;
  }
}

.ticket-buttons {
  text-align: right;
  a {
    font-family: $font-family-condensed-final;
    font-weight: 500;
    font-size: $font-size-base*.875;
    letter-spacing: 0.026em;
    text-transform: uppercase;
    border-radius: $border-radius-lg;
    align-self: center;
    width: auto;
    display: inline-block;
    padding: $spacer*1 $spacer*1.6;
    background-color: $blue;
    color: $white;
    margin-right: $spacer*1.6;
    &.close-ticket{
	    background: #e6e6e6;
	    color: #555;
	    border: 1px solid #c3c3c3;
	    &:hover{
	    	border: 1px solid #AAA;
	    	background: #555;
	    	color:#FFF;
	    }
    }
    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      background-color: darken($blue,15%);
    }
  }
}
