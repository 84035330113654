$table-red: #cc0000;

.section-documents {
	@media only screen and (max-width:1100px) {
		.title h3{
			padding-bottom:0 !important
		}
	}
	h3{
		max-width: none !important;
		position: relative;
		.slt{
  	  text-shadow: 0px 1px 0px #000000;
			font-size:18px;
	    font-family: 'Helvetica', sans-serif;
	    font-weight: bold;
    	@media only screen and (max-width:1100px) {
    		display: block;
    		margin-top: 10px;
    		margin-bottom:12px
    	}
    	@media only screen and (max-width:450px) {
    		margin-bottom:0
    	}
	    a{
	    	margin-left: 5px;
    		@media only screen and (max-width:1100px) {
	    		margin-left:0;
	    		margin-right: 5px;
	    	}
	    }
			span{
				font-weight: normal
			}
		}
		.removeFilter {
  	  text-shadow:none;
  	  position: absolute;
  	  top:25px;
  	  right: 0;
	    color: #FFF;
	    display: inline-block;
	    background: transparent;
	    border: 2px solid #FFF;
	    padding: 5px 15px;
	    font-size: 16px;
	    opacity: 1;
	    transition: opacity .2s;
    	@media only screen and (max-width:450px) {
    		position: static;
    		margin-bottom:10px
    	}
	    &:hover{
	    	opacity: .8
	    }
		}
	}
	&.real-section{
		background: #FFF;
	}
  #prefooter {
    .logos {
      display: none;
    }
  }
  #header .menu ul li{
    &.m-usuario {
      display: inline-block!important;
      background: none;
      a {
        color: $contact-icon;
        font-size: $font-size-base*1.125;
      }
    }
  }
  &__table {
    margin-top: -$spacer*4.8;
  }
  .holding-value-table {
    border-collapse: separate;
    border-spacing: 0;
    thead {
      tr {
        th {
          vertical-align: middle;
          height: $spacer*4.8;
          padding-top: 0;
          padding-bottom: 0;
          border-top: none;
          border-bottom: none;
          border-right: $spacer*.1 solid $white;
          &.tcenter{
			    	text-align: center;
		    	}
          &:last-of-type {
            border-right: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
        	border-top:0;
        	border-bottom:2px solid #FFF;
          &.tbeid{
    				padding: 0;
    				a.lnk.nhhh{
    					text-decoration: none!important
    				}
    				a.lnk{
    					display: block;
    					transition: background .2s;
          	  text-decoration: underline;
            	background: rgba(255,255,255,0);
	            &.hoverx:hover,
	            &.hoverx,
	            &:hover {
            		text-decoration: none;
	            	background: rgba(255,255,255,.7);
	            }
            	&.alt{
          	  	text-decoration: none;
          	  	color:#4470b5;
          	  	font-weight: bold;
	           		&.hoverx:hover,
		            &.hoverx,
		            &:hover {
	            		text-decoration: underline;
	            		color:#06419c;
		            	background: rgba(255,255,255,.7);
		            }
            	}
    				}
          }
          &.trdsize{
			    	font-size:13px
		    	}
        }
      }
    }
    &__negative-row {
      background-color: rgba($table-red,0.1);
      color: $table-red;
    }
  }
  &__pagination {
    @extend .flex-r;
    margin-top: $spacer*1.6;
    margin-bottom: $spacer*1.6;
    justify-content: flex-end;
    span {
    	display: inline-block;
      height: $spacer*3.2;
      width: $spacer*3.2;
      background-color: #ebebeb;
      font-size: $font-size-base*1.25;
      font-family: $font-family-condensed-final;
      font-weight: 400;
      margin-right: $spacer*1.2;
      line-height: $spacer*3.2;
      color: $blue;
      text-align: center;
      a{
      	display: block;
      	line-height: 1.2;
      line-height: $spacer*3.2;
      height: $spacer*3.2;
      width: $spacer*3.2;
      }
      &.active:hover,
      &.active{
      	color:  #ebebeb;
      	background-color:$blue;
      }
      &:hover {
        background-color: darken(#ebebeb,15%);
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    .section-documents__next-page {
      font-family: $font-family-condensed-final;
      font-weight: 500;
      font-size: $font-size-base*.875;
      line-height: 1.5;
      text-transform: uppercase;
      border-radius: $border-radius-lg;
      height: $spacer*2.6;
      align-self: center;
      width: auto;
      padding-left: $spacer*1.6;
      background-color: $blue;
      padding-right: $spacer*1.6;
      color: $white;
      &:hover {
        background-color: darken($blue,15%);
      }
    }
  }
}


#app-content-table{
	background: #FFF
}
body{
	background: url('images/background.jpg') transparent top center no-repeat fixed;
	background-size: 100%;
}
#header .app-inside{
	height: 0
}
.bbxt-table .tr-middle-text td{
	vertical-align: middle
}

#content .title .spxr{
	padding-top: 10px;        			
	padding-bottom: 10px;
	min-height: 40px;     			
	font-size: 20px;
	line-height: 1;
}
.section-documents h3 {
    max-width: none !important;
    position: relative;
}
.section-documents .text-error{
	text-align: center;
}
.section-documents h3 .slt a {
	margin-left: 2px;
	margin-right: 17px;
	font-size: 18px;
}
#content .real-section  .title .slt a{
	position: relative;
}
#content .real-section  .title .slt a:not(:first-child):before {
	position: absolute;
	content: '|';
	left: -14px;
	font-size: 16px;
	opacity: .6;
	top: 3px;
	height: 100%;
	width: 1px;

}
#content .title  .title-button{
	text-shadow: none;
	margin-right:5px;
	background: #6f6f6f;
	border: 1px solid #555;
	transition: all .2s;	
}
#content .real-section  .title .slt a:after {
	position: absolute;
	content: '';
	width: 0%;
	background-color: #fff;
	height: 3px;
	left: 0;
	bottom: -5px;
	transition: width ease 0.3s;
}
#content .real-section .title a:hover:after {
	width: 100%;
}
#content .title h3.xr{
	padding-top: 0;
}
#content .title  .title-button.active-x{
	font-size: 24px;
	background-color: #4470b5;
	border-color: #4470b5;
}
#content .title  .title-button:hover{

	background-color: #4470b5;
	border-color: #4470b5;
}
#content .title .title-no-button{
	margin-right: 45px;
}

@media screen and (max-width: 1100px){
	#content .title .spxr{
		min-height: 10px;
	}
	#content .title .title-no-button{
		display: block;
		padding-top: 15px;
		margin-bottom: 10px;
	}
}
@media screen and (max-width:568px){
	.user-menu .user-menu__list a.user-menu__option{
		margin-bottom: 10px;
	}
	body .user-menu{
		margin-bottom: 10px;
	}
}
.submenu-producer{
	width: 100%;
	padding-top: 10px;
	display: flex;
	min-width: 100%;
}
@media screen and (max-width: 500px){
	#content .title h3 .input{
		text-align: center
	}
	#content .title h3 .input .lbx{
		display: block;
		text-align: center;
		padding-bottom: 10px;
	}
}
@media screen and (max-width: 1100px){
	body .user-menu.user-menu-producer.user-menu-producer-in {
    margin-bottom: 51px;
    margin-top: 20px;
	}
}

@media screen and (max-width: 800px){
	#content .title .title-no-button{
		padding-top: 0;
		margin-top: -10px;
		margin-bottom: 15px;
	}
}
@media screen and (max-width: 700px){
	#content .title .btn-currency {
		margin-top: 15px !important
	}
	#content .title .title-no-button{
		display: none;
		margin-top: 0;
	}
}

@media screen and (max-width: 480px){
	.user-menu.user-menu-producer-in .submenu-producer .user-menu__option{
		display: inline-block
	}
	.section-documents .text-error{
		padding-bottom: 65px;
	}
	.table-xtrt.table-error-mobile{
		overflow: hidden
	}
	#content .title .title-no-button{
		margin-right: 0;
		margin-bottom: 20px;
		font-size:30px
	}
	#content .user-menu.user-menu-producer-in + h3{
		font-size:30px
	}
	#content .user-menu.user-menu-producer-in + h3,
	.user-menu.user-menu-producer-in .user-menu__list,
	.user-menu.user-menu-producer-in .submenu-producer{
		display: block;
		text-align: center
	}
}
@media screen and (max-width: 710px){
	.user-menu.user-menu-producer-in .user-menu__list a.user-menu__option{
		margin-bottom: 0
	}
	.section-documents .text-error{
		text-align: left;
		padding-left:20px;
		margin-top: -40px;
		padding-bottom: 15px;
	}
	#app-body .user-menu.user-menu-producer .drop-down-list{
		display: block
	}
	.user-menu.user-menu-producer .user-menu__list{
		padding-top: 30px;
	}
	.user-menu.user-menu-producer-in .user-menu__list{
		padding-top: 20px;
		padding-bottom: 15px;
		border-bottom:1px solid rgba(255,255,255,.5);
	}
	.user-menu.user-menu-producer .submenu-producer{
		margin-bottom: 5px;
		text-align: center;
		padding-bottom: 15px;
		border-bottom:1px solid rgba(255,255,255,.5);
	}
	.user-menu.user-menu-producer .user-menu__list a{
		font-size:18px;		
	}
	body .user-menu.user-menu-producer{
		margin-bottom: 11px;
	}
	.user-menu.user-menu-producer .submenu-producer .user-menu__option{
		font-size:18px;
		margin-top: 0 !important
	}
	.user-menu.user-menu-producer .user-menu__information{
		margin-bottom: 10px !important
	}
	.user-menu.user-menu-producer.user-menu-producer-in .user-menu__information{
		margin-bottom: 15px !important
	}
	#header .app-inside .logo > a{
		height: 40px;
    overflow: hidden;
    display: inline-block;
	}
	.user-menu.user-menu-producer .user-menu__list{
		padding-top: 0
	}
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip.tooltip-top, .tooltip.bs-tether-element-attached-bottom {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.tooltip-top .tooltip-inner::before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.tooltip-right, .tooltip.bs-tether-element-attached-left {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.tooltip-right .tooltip-inner::before, .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  top: 50%;
  left: 0;
  margin-top: -5px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.tooltip-bottom, .tooltip.bs-tether-element-attached-top {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.tooltip-bottom .tooltip-inner::before, .tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  top: 0;
  left: 50%;
  margin-left: -5px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.tooltip-left, .tooltip.bs-tether-element-attached-right {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip.tooltip-left .tooltip-inner::before, .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
  top: 50%;
  right: 0;
  margin-top: -5px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.tooltip-inner::before {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
#content .title h3{
	padding-top: 0;
}
body .holding-value-table .txdate{
	padding-left: 0;
	padding-right: 0;
}
body .holding-value-table th, body .holding-value-table td .ittpadd, body .holding-value-table td{
	padding-top: 13px;   
	vertical-align: bottom;     			
}
.section-documents .holding-value-table tbody tr td.tbeid a.lnk {
	padding-top: 14px;
}
